.custom-multiplier-card {
  margin: 10px;
}

.dark-theme .accordion .custom-multipliers-accordion {
  background-color: transparent !important;
  padding: 0 !important;

}

.accordion .custom-multipliers-accordion {
  background-color: transparent !important;
  padding: 0 !important;
}

.accordion .custom-multiplier-card-body {
  padding: 10px 10px 10px 20px !important;
}

.accordion .accordion-item {
  background-color: transparent !important;
}