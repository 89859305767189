
.sp-container .main-profile-overview .btn-icon-list button {
  border-radius: 100%;
}

.main-content-body-profile {
  .nav {
    flex-direction: column;
    padding: 20px 20px 20px 15px;
    border-bottom: 1px solid var(--border);
    margin-bottom:1.3rem;
  }

  .main-nav-line .nav-link {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    color: var(--gray-800);

    &.active {
      font-weight: 700;
      color: var(--primary-bg-color);

      &::before {
        background-color: var(--primary-bg-color);
      }
    }
  }
}

@media (min-width: 576px) {
  .main-content-body-profile .nav {
    flex-direction: row;
    align-items: center;
  }

  .main-content-body-profile .nav {
    padding: 18px 14px 17px 30px;
  }
}

@media (min-width: 992px) {
  .main-content-body-profile .nav {
    padding-left: 20px;
  }
}

@media (min-width: 1200px) {
  .main-content-body-profile .nav {
    padding-left: 25px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .main-content-body-profile .main-nav-line .nav-link.active::before {
    width: auto;
    top: auto;
    left: 0;
    right: 0;
    height: 2px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .main-content-body-profile .main-nav-line .nav-link + .nav-link {
    margin-top: 0;
    margin-left: 20px;
  }
}

@media (max-width: 575px) {
  .main-content-body-profile .main-nav-line .nav-link.active::before {
    left: 0;
  }
}

.profile-tab .main-nav-line .nav-link {
  font-weight: 500;
}

.main-nav-line .nav-link {
  color: #3c4858;
  position: relative;
  border: 0 solid #ededf5;
  padding: 7px;
}

.main-content-body-profile .main-nav-line .nav-link {
  color: #3c4858;
  position: relative;
  padding: 8px 25px;
}

.profile-tab.tab-menu-heading .main-nav-line .nav-link{
  position: relative;
  padding: 4px 20px;
}

.profile-tab .main-nav-line .nav-link {
  font-weight: 500;
}

@media (min-width: 769px){
  .main-nav-line .nav-link + .nav-link {
    margin-top: 0;
    margin-left: 5px;
    padding: 8px 25px;
  }
}

.card-header:first-child {
  border-radius: 11px 11px 0 0;
}

@media (max-width: 576px){
  .main-nav-line .nav-link + .nav-link {
    margin-top: 10px !important;
    margin-left: 0 !important;
  }
}

.profile-tab.tab-menu-heading {
  font-size: 14px !important;
}

a.option-dots {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  font-size: 18px;
  border-radius: 50px;
  border: 1px solid var(--border);
  vertical-align: middle;
  margin-right: 5px;
  padding: 0;
}

.custom-multiselect {
  height: 40px !important;
}