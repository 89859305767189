.result-table {
  #main {
    max-width: 80%;
    margin: 0 auto;
  }

  .carousel-control-next,
  .carousel-control-prev {
    background-color: var(--primary-bg-color);
    top: 0.5rem;
  }
  .carousel-control-prev {
    left: 5px;
  }
  .carousel-control-next {
    right: 5px;
  }

  #main .header__name {
    font-size: 20px;
    text-align: left;
    height: 28px;
    width: 100%;
    text-transform: uppercase;
  }

  #main .game-state {
    margin-top: 40px;
  }

  #main .game-stats-info {
    margin-top: 40px;
  }

  #main table thead tr td {
    font-weight: bold;
  }

  #main .game-stats-info__stats-class {
    margin-top: 20px;
  }

  #main table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #main .narrow-table {
    margin-left: 190px;
    width: 55%;
  }

  #main td,
  #main th {
    border: 1px solid #dddddd;
    padding: 8px;
    font-size: 12px;
  }

  #win_lines_table > tbody > tr > td,
  #symbols_table > tbody > tr > td,
  #winning_details > tbody > tr > td {
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position-x: center !important;
    height: 50px;
  }
  &.not-available {
    pointer-events: none;
    position: relative;
    &::after {
      content: "This feature isn't available for this game yet.";
      position: absolute;
      width: 100%;
      height: 700px;
      top: 0;
      left: 0;
      backdrop-filter: blur(2px);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }
  }
}

.bordered-row {
  border-left: 3px solid #4ec2f0;
  border-right: 3px solid #4ec2f0;
}

.fifth-row {
  border-top: 3px solid #4ec2f0;
  border-left: 3px solid #4ec2f0;
  border-right: 3px solid #4ec2f0;
}

.last-row {
  border-bottom: 3px solid #4ec2f0;
  border-left: 3px solid #4ec2f0;
  border-right: 3px solid #4ec2f0;
}
