/* ###### 4.5 Morris ###### */

.morris-hover.morris-default-style {
  border-radius: 0;
}

/* ###### 5.2 Charts  ###### */

.main-donut-chart {
  position: relative;
  border-radius: 50%;
  overflow: hidden;

  .slice {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .chart-center {
    position: absolute;
    border-radius: 50%;

    span {
      display: block;
      text-align: center;
    }
  }
}

#ecom-chart {
  height: 200px !important;
}

.legend {
    width: 15px;
    height: 10px;
    display: block;
    border-radius: 2px;
    margin-right: 9px;
    margin-top: 6px;
}

.list-unstyled {
  .legend-content {
    display: inline-block;
    vertical-align: top;
  }

  li {
    display: flex;
    margin-bottom: 20px;
  }
}

.dot-label {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  animation: ani 1s linear infinite;
  left: 9px;
  bottom: -12px;
  position: relative;
}

#summary-chart {
  height: 204px;
  width: 100%;
}

.summary.chart-legend {
  top: 169px;
}

.morris-wrapper-demo {
  height: 200px;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
}

@media (min-width: 576px) {
  .morris-wrapper-demo {
    height: 300px;
  }
}

.morris-donut-wrapper-demo {
  height: 200px;
}

@media (min-width: 576px) {
  .morris-donut-wrapper-demo {
    height: 250px;
  }
}

.chartjs-wrapper-demo {
  // height: 250px;
}

@media (max-width: 330px) {
  .chartjs-wrapper-demo {
    // width: 290px;
  }
}

@media (min-width: 992px) {
  .chartjs-wrapper-demo {
    // height: 300px;
  }
}

.chartist-wrapper-demo {
  height: 200px;
}

@media (min-width: 768px) {
  .chartist-wrapper-demo {
    height: 300px;
  }
}

.flotChart6 {
  width: 100%;
  height: 180px;
}

.chart-icons {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.sales-flot {
  .flot-chart .flot-x-axis > div span {
    &:first-child {
      display: block;
      text-transform: uppercase;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 500;
      font-size: 10px;
      color: $gray-600;
      top: 10px;
      position: relative;
    }

    &:last-child {
      display: block;
      font-size: 16px;
      font-weight: 700;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      color: $gray-900;
      line-height: 1;
      top: 15px;
      position: relative;
    }
  }

  position: relative;
}

.sales-bar-chart {
  position: relative;
  width: auto;
  height: 160px;
}
.chart-legend {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  z-index: 10;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;

  div {
    display: flex;
    align-items: center;

    + div {
      margin-left: 15px;
    }
  }

  span {
    width: 10px;
    height: 4px;
    display: block;
    margin-right: 5px;
  }
}

@media (min-width: 576px) {
  .chart-legend div + div {
    margin-left: 30px;
  }
}

@media (min-width: 576px) {
  .chart-legend span {
    width: 40px;
    margin-right: 10px;
  }
}

.chart-wrapper {
  position: relative;
  margin-left: -13px;
  margin-right: -13px;
}

@media (min-width: 576px) {
  .chart-wrapper {
    margin-left: -10px;
    margin-right: -15px;
  }
}

.card-dashboard-twelve .flot-chart {
  width: 100%;
  height: 200px;
  position: relative;

  .flot-x-axis > div span {
    &:first-child {
      display: block;
      text-transform: uppercase;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 500;
      font-size: 10px;
      color: #7987a1;
    }

    &:last-child {
      display: block;
      font-size: 16px;
      font-weight: 700;
      font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      color: #1c273c;
      line-height: 1;
    }
  }
}

@media (min-width: 768px) {
  .card-dashboard-twelve .flot-chart {
    height: 250px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-twelve .flot-chart {
    height: 300px;
  }
}