.accordion .card-body {
  border: 0 !important;
}

.currencies-table tbody td {
  height: 24px;
  padding: 4px 15px 4px 10px;
  line-height: 1.5;
}

.dark-theme .accordion .accordion-header button {
  color: #fff !important;
  background-color: #262838 !important;
  border-radius: 10px;
  margin-left: 15px;
  width: 95%;
  margin-bottom: 10px;
}

.accordion .accordion-header button {
  background-color: var(--gray-200) !important;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  width: 95%;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.dark-theme .accordion-button::after {
  width: 2rem;
  height: 2rem;
  background-size: 2rem;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTggMTAuMDAwMkwxMS43NSAxMy43NTAyTDE1LjUgMTAuMDAwMiIgc3Ryb2tlPSIjQ0ZEQUVDIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==") !important;
}

.accordion-button::after {
  width: 2rem;
  height: 2rem;
  background-size: 2rem;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTggMTAuMDAwMkwxMS43NSAxMy43NTAyTDE1LjUgMTAuMDAwMiIgc3Ryb2tlPSIjOUI5QjlCIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==") !important;
}