.table td {
  padding: 8px;
}

.table td:first-child,
table.table-bordered.dataTable thead tr:first-child th:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff;
}

.dark-theme .table td:first-child ,
.dark-theme table.table-bordered.dataTable thead tr:first-child th:first-child {
  background-color: #2a2e3f;
}

.active-filter {
  font-weight: bold;
  color: #38cab3;
}

.range-filter {
  margin-right: 12px;
  cursor: pointer;
}

.filter-tab {
  cursor: pointer;

}

.active-tab {
  font-weight: bold;
  color: #38cab3;
}
