.dark-theme .ant-picker-header,
.dark-theme .ant-picker-body,
.dark-theme .ant-picker-content,
.dark-theme .ant-picker-footer,{
  background-color: #2a2e3f;
  color: whitesmoke;
}

.dark-theme .ant-picker-cell-inner,
.dark-theme .ant-picker-time-panel-cell-inner,
.dark-theme .ant-picker-body th,
.dark-theme .ant-picker-header-next-btn span,
.dark-theme .ant-picker-header-prev-btn span,
.dark-theme .ant-picker-header-super-next-btn span,
.dark-theme .ant-picker-header-super-prev-btn span,
.dark-theme .ant-btn-sm span,{
  color: whitesmoke !important;
}

.dark-theme :where(.css-dev-only-do-not-override-acm2ia).ant-picker-dropdown .ant-picker-time-panel-column >li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner,
.dark-theme :where(.css-acm2ia).ant-picker-dropdown .ant-picker-time-panel-column >li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: #3c435b;
}

.dark-theme :where(.css-dev-only-do-not-override-acm2ia).ant-picker-dropdown .ant-picker-cell-disabled,
.dark-theme :where(.css-acm2ia).ant-picker-dropdown .ant-picker-cell-disabled {
  background-color: rgba(35, 37, 52, 0.3);
}

:where(.css-dev-only-do-not-override-acm2ia).ant-picker-dropdown,
:where(.css-acm2ia).ant-picker-dropdown {
  z-index: 1200;
}

.table td:first-child,
table.table-bordered thead tr:first-child th:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff;
}

.dark-theme .table td:first-child ,
.dark-theme table.table-bordered thead tr:first-child th:first-child {
  background-color: #2a2e3f;
}