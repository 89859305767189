:root {
  --default-color: #4a4a69;
  --primary-bg-color: #38cab3;
  --primary-bg-hover: #5cd3b9;
  --primary-bg-border: #38cab3;
  --primary02: rgba(28, 213, 174, 0.2);
  --primary05: rgba(28, 213, 174, 0.5);
  --bg-hover: #fcfcff;
  --primary-transparentcolor: rgba(28, 213, 174, 0.2);
  --transparentprimary-transparentcolor: rgba(255, 255, 255, 0.05);
  --transparent-body: #38cab3;
  --transparent-theme: rgba(0, 0, 0, 0.1);
  --blue: #{var(--primary-bg-color)};
  --indigo: #{var(--primary-bg-color)};
  --purple: #ad4ffa;
  --pink: #f754fa;
  --red: #f34343;
  --orange: #fd7e14;
  --yellow: #ffbd5a;
  --green: #24d5b8;
  --teal: #29ddee;
  --cyan: #4ec2f0;

  --white: #FFFFFFFF;
  --white-1:rgba(255, 255, 255, 0.1);
  --white-2:rgba(255, 255, 255, 0.2);
  --white-3:rgba(255, 255, 255, 0.3);
  --white-4 :rgba(255, 255, 255, 0.4);
  --white5: rgba(255, 255, 255, 0.5);
  --white-6 :rgba(255, 255, 255, 0.6);
  --white-7 :rgba(255, 255, 255, 0.7);
  --white-8 :rgba(255, 255, 255, 0.8);
  --white-9 :rgba(255, 255, 255, 0.9);

  --white-08:rgba(255, 255, 255, 0.08);
  --white05: rgba(255, 255, 255, 0.05);
  --white75: rgba(255, 255, 255, 0.075);

  --gray: var(--gray-600);
  --gray-100: #f9f9fb;
  --gray-200: #f0f0f8;
  --gray-300: #e1e1ef;
  --gray-500: #949eb7;
  --gray-600: #7987a1;
  --gray-700: #4d5875;
  --gray-900: #323251;
  --gray-dark: #383853;
  --secondary: var(--gray-600);
  
  --success: var(--green);
  --info: var(--info);
  --warning: var(--yellow);
  --danger: var(--red);
  --light: var(--gray-100);
  --dark: #3b4863;
  
  --black: #000;
  --black-1:rgba(0, 0, 0, 0.1);
  --black-2:rgba(0, 0, 0, 0.2);
  --black-3:rgba(0, 0, 0, 0.3);
  --black-4:rgba(0, 0, 0, 0.4);
  --black-5:rgba(0, 0, 0, 0.5);
  --black-6:rgba(0, 0, 0, 0.6);
  --black-7:rgba(0, 0, 0, 0.7);
  --black-8:rgba(0, 0, 0, 0.8);
  --black-9:rgba(0, 0, 0, 0.9);
  --black-05:rgba(0, 0, 0, 0.05);
  
  --border: #ededf5;

  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 992px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;

  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

#global-loader {
  position: fixed;
  z-index: 50000;
  background: var(--white);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.loader-img {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 43%;
  left: 0;
  margin: 0 auto;
  text-align: center;
}

.fade {
  transition: opacity 0.15s linear;

  &:not(.show) {
    opacity: 0;
  }
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.media {
  display: flex;
  align-items: center;
}

.media-body {
  flex: 1;
}

.close {
  font-size: 1.3125rem;
  font-weight: 400;
  line-height: 1;
  color: var(--black);
  text-shadow: 0 1px 0 var(--white);
  opacity: 0.5;
  float: right;

  &:not(:disabled):not(.disabled) {
    &:hover,
    &:focus {
      opacity: 0.75;
    }
  }
}

@media (max-width: 991px) {
  .close {
    font-size: 1.3125rem;
    font-weight: 400;
    line-height: 1;
    color: var(--black);
    text-shadow: 0 1px 0 var(--white);
    opacity: 0.5;
    position: absolute;
    right: 10px;
  }
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
  color: inherit;
}

a.close.disabled {
  pointer-events: none;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(0.2);
    opacity: 1;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}
@media print {
  * {
    text-shadow: none !important;
    box-shadow: none !important;

    &::before,
    &::after {
      text-shadow: none !important;
      box-shadow: none !important;
    }
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
    border: 1px solid $gray-500;
    page-break-inside: avoid;
  }

  blockquote {
    border: 1px solid $gray-500;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body,
  .container {
    min-width: 992px !important;
  }
}

/* ########## BOOTSTRAP OVERRIDES ########## */

/* ############### PAGE LAYOUT STYLES ############### */

/* ###### 6.3 Global  ###### */

body {
  font-family: "Poppins", sans-serif;
}

.main-body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.main-body::before {
  content: "main";
  position: fixed;
  top: 45%;
  left: 70%;
  transform: translate3d(-50%, -58%, 0);
  font-size: 1300px;
  font-weight: 600;
  letter-spacing: -10px;
  line-height: 0.5;
  opacity: 0.02;
  z-index: -1;
  display: none;
}

.main-table-reference {
  background-color: var(--white);

  > {
    thead > tr > {
      th,
      td {
        padding: 8px 10px;
        border: 1px solid var(--border);
        font-size: 13px;
      }
    }

    tbody > tr > {
      th,
      td {
        padding: 8px 10px;
        border: 1px solid var(--border);
        font-size: 13px;
      }
    }

    thead > tr > {
      th,
      td {
        background-color: $gray-100;
        color: $gray-600;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        border-bottom: 0;
      }
    }
  }
}

/* ############### TEMPLATE STYLES ############### */
.main-icon-group {
  text-align: center;
  padding: 20px;
  color: $gray-800;
}

.font-awesome.main-icon-group i {
  font-size: 14px;
}

.main-icon-group i {
  line-height: 3;
  font-size: 18px;
}

.brround {
  border-radius: 50%;
}

/* ############### UTILITIES/HELPER CLASSES ############### */

.h-6 {
  height: 2rem !important;
}

.country-Flag img {
  width: 1.5rem;
  height: 1rem;
  line-height: 0.1rem;
  font-size: 0.75rem;
}

.dropdown.flag .dropdown-item span {
  font-size: 14px;
}

.btn.btn-default.nav-link {
  height: 38px;
  width: 38px;
  text-align: center;
  border-radius: 50%;
  line-height: 1;
  font-size: 17px;
  padding: 1px;
}

.d-md-block.nav.nav-item.nav-link {
  padding: 0.5rem 0.7rem;
}

.dropdown-menu .dropdown-header {
  text-transform: uppercase;
  font-size: 0.73333333rem;
  font-weight: 500;
  border-bottom: 1px solid var(--border);
}

#dropdownMenuButton2 .badge-pill {
  padding: 5px 7px;
  font-size: 10.5px;
}

.drop-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--white);
  position: relative;
}

.dropdown-item .content {
  margin-left: 15px;
  width: 200px;
  white-space: normal;
}

.main-header .nav-link .header-badge,
.main-header .nav-item .header-badge {
  position: absolute;
  top: 0;
  right: 0;
  display: block !important;
  padding: 3px 5px !important;
  font-size: 10px;
  z-index: 9999;
  border-radius: 50px;
  line-height: 1.1;
}

.pulse {
  display: block;
  position: absolute;
  top: 6px;
  right: 8px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: $success;

  &:before {
    content: "";
    top: -4px;
    right: -4px;
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    background: rgba(34, 192, 60, 0.6);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(34, 192, 60, 0.9);
    animation: pulse 1s linear infinite;
  }
}

.pulse-danger {
  display: block;
  position: absolute;
  top: 6px;
  right: 5px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #ee335e;

  &:before {
    content: "";
    top: -4px;
    right: -4px;
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    background: rgba(238, 51, 94, 0.8);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(238, 51, 94, 0.9);
    animation: pulse 1s linear infinite;
  }
}

.main-message-list a {
  .desc {
    font-size: 11px;
    color: $default-color;
    margin-left: 10px;
    white-space: break-spaces;
  }

  .name {
    font-size: 0.8rem;
    transition: all 0.2s ease-in-out;
    border-radius: 2px;
    color: $default-color;
    margin-left: 10px;
    white-space: break-spaces;
  }

  .time {
    font-size: 11px;
    color: #7987a1;
    font-weight: 400;
  }
}

.menu-header-content {
  padding: 1.2rem;
}

.chat-scroll,
.Notification-scroll,
.chat-scroll1,
.Notification-scroll1,
.cart-scroll {
  max-height: 280px;
  position: relative;
}

.cover-image {
  background-size: cover !important;
}

.notifyimg {
  i {
    color: var(--black);
    float: left;
    height: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    width: 40px;
    font-size: 21px;
  }

  border-radius: 25%;
  width:  37px;
  height: 37px;
}

.notification-label {
  font-size: 13.5px;
  transition: all 0.2s ease-in-out;
  border-radius: 2px;
  color: $default-color;

  &:hover {
    color: var(--primary-bg-color);
  }
}

.main-message-list .name:hover {
  color: var(--primary-bg-color);
}

.notification-subtext {
  font-size: 12px;
  color: #7987a1;
}

/* ###### 4.8 Box-shadows ###### */

.box-shadow-primary {
  box-shadow: 0 5px 10px rgba(118, 109, 249, 0.25);
}

.box-shadow-success {
  box-shadow: 0 5px 10px rgba(26, 156, 134, 0.25);
}

.box-shadow-warning {
  box-shadow: 0 5px 10px rgba(255, 189, 90, 0.25);
}

.box-shadow-danger {
  box-shadow: 0 5px 10px rgba(220, 53, 69, 0.25);
}

.box-shadow-pink {
  box-shadow: 0 5px 10px rgba(241, 0, 117, 0.25);
}

.box-shadow-info {
  box-shadow: 0 5px 10px rgba(78, 194, 240, 0.25);
}

.main-notification-list {
  .las {
    font-size: 13px;
  }

  a:hover {
    background: $bg-hover;
    color: $default-color;
  }
}

.main-message-list a:hover {
  background: $bg-hover;
  color: $default-color;
}

.progress-sm {
  height: 4px;
}

.card-chart {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 3px;
}

.browser-stats {
  i {
    height: 32px;
    width: 32px;
    text-align: center;
    border-radius: 50%;
    line-height: 32px;
  }

  .item {
    padding: 11px 1rem;
  }

  h6 {
    font-size: 13px;
    margin-bottom: 2px;
  }

  .sub-text {
    display: block;
    color: $gray-500;
    font-size: 10.6px;
  }
}

.sales-card {
  .badge {
    border-radius: 30px;
    background-color: #c6d8ff;
    padding: 4px 15px;
    color: #4680ff;
    font-weight: 600;
  }

  .sales-icon {
    color: var(--white);
    font-size: 18px;
    padding: 40px 40px 20px 20px;
    border-radius: 50%;
    position: absolute;
    top: -30px;
    right: -30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &:hover .sales-icon {
    font-size: 25px;
  }
}

.media img {
  width: 35px;
  height: 35px;
}

.sales-session {
  li {
    list-style-type: none;
    margin-bottom: 1.2rem;
  }

  margin-left: 0;
  padding-left: 0;
}

.font-weight-semibold {
  font-weight: 500 !important;
}

.order-list {
  .list {
    padding-left: 0;

    .list-item:first-child {
      padding-top: 0;
    }

    &.list-noborders .list-item {
      border: none;
    }

    .list-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0 0 19px 0;
    }
  }

  .list-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #dee2e6;
  }
}

.order-list img {
  border-radius: 6px;
}

@keyframes ani {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.4);
  }

  50% {
    transform: scale(1.2);
  }

  70% {
    transform: scale(1.4);
  }

  90% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.row-cards > {
  .col,
  [class*="col-"] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.row-deck > {
  .col,
  [class*="col-"] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .col .card,
  [class*="col-"] .card {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
}

.area.chart-legend {
  top: 125px;
}

.pricing-card {
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }

  .display-5 {
    font-size: 2.3rem;
    line-height: 1.2;
    padding: 25px 0 0 !important;
  }

  .list-unstyled li {
    display: flow-root;
    margin-bottom: 0;
    padding: 9px 0;
  }
}

/*------ Pricing Styles ---------*/

.panel.price {
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;

  > .panel-heading {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
  }

  h3 {
    margin-bottom: 0;
    padding: 20px 0;
  }
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid var(--white)-2;
  background: var(--white);
}

.panel.price {
  > .panel-heading {
    color: $dark;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .list-group-item {
    &:last-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:first-child {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }

  margin-bottom: 1.5rem;
}

.price {
  .panel-footer {
    border-bottom: 0;
    background-color: var(--white);
    border-left: 0;
    border-right: 0;
  }

  &.panel-color > .panel-body {
    background-color: var(--white);
  }
}

.panel-body {
  padding: 15px;
  border: 1px solid var(--border);

  .lead {
    strong {
      font-size: 40px;
      margin-bottom: 0;
    }

    font-size: 20px;
    margin-bottom: 0;
    padding: 10px 0;
  }
}

.panel-footer {
  padding: 10px 15px;
  background-color: var(--white);
  border-top: 1px solid var(--border);
  border-left: 1px solid var(--border);
  border-right: 1px solid var(--border);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel.price .btn {
  border: 0;
}

.pricing .list-unstyled li {
  display: flow-root;
  margin-bottom: 0;
  padding: 15px 0;
}

.demo-gallery {
  > ul > li a {
    border-radius: 3px;
    display: block;
    overflow: hidden;
    position: relative;
    float: left;
    height: 100%;
    width: 100%;

    > img {
      opacity: 1;
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
      width: 100% !important;
    }

    &:hover {
      > img,
      .demo-gallery-poster > img {
        opacity: 0.5;
      }
    }

    .demo-gallery-poster {
      background-color: var(--black)-1;
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      -webkit-transition: background-color 0.15s ease 0s;
      -o-transition: background-color 0.15s ease 0s;
      transition: background-color 0.15s ease 0s;

      > img {
        left: 50%;
        margin-left: -10px;
        margin-top: -10px;
        opacity: 0;
        position: absolute;
        top: 50%;
        -webkit-transition: opacity 0.3s ease 0s;
        -o-transition: opacity 0.3s ease 0s;
        transition: opacity 0.3s ease 0s;
      }
    }

    &:hover .demo-gallery-poster {
      background-color: var(--black)-5;
    }
  }

  &.dark > ul > li a {
    border: 3px solid #04070a;
  }
}

.card-body + .card-body {
  border-top: 1px solid var(--border);
}

.tab-content {
  overflow: hidden;

  img {
    width: 100%;
  }
}

.colors {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

.checked {
  color: #ff9f1a;
}

.size {
  margin-right: 10px;

  &:first-of-type {
    margin-left: 40px;
  }
}

@-webkit-keyframes opacity {
  0% {
    opacity: 0;
    transform: scale(3);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
    transform: scale(3);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.colorinput {
  margin: 0;
  position: relative;
  cursor: pointer;
}

.colorinput-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.colorinput-color {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 3px;
  border: 1px solid #eaf0f7;
  color: var(--white);
  box-shadow: 0 1px 2px 0 var(--black)-05;

  &:before {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    height: 1.25rem;
    width: 1.25rem;
    transition: 0.3s opacity;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
      no-repeat center (center / 50%) 50%;
  }
}

.colorinput-input:checked ~ .colorinput-color:before {
  opacity: 1;
}

.item-card {
  .cardtitle {
    span {
      display: block;
      font-size: 0.75rem;
    }

    a {
      color: #1f252d;
      font-weight: 500;
      text-decoration: none;
    }
  }

  .cardprice {
    position: absolute;
    top: 17px;
    right: 15px;

    span {
      &.type--strikethrough {
        opacity: 0.7;
        text-decoration: line-through;
      }

      display: block;
      color: #1f252d;
    }
  }

  .relative {
    position: relative;
  }
}

/*------icons-list---------*/

.main-icon-list {
  padding: 0;
}

.icons-list-item i {
  font-size: 1rem !important;
  line-height: 3;
}

.icons-list {
  list-style: none;
  margin: 0 -1px -1px 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.icons-list-item {
  text-align: center;
  height: 3rem;
  width: 3rem;
  line-height: 3.3;
  display: block;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9ebfa;
  margin: 4px;
  border-radius: 5px;
  color: #6c757e;
  fill: #6c757e;
  flex: 1 0 3rem;
}

.icons-list-item .bs-tooltip-start {
  padding: 10px 0.4rem;
}

.flag {
  margin: 8px auto !important;
  width: 30px;
  height: 30px;
  display: block;
  vertical-align: middle;
  box-shadow: none;
}

.payment {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  margin-top: 9px;
  display: block;
  vertical-align: middle;
  box-shadow: none;
}

.icons-list .flag-icon {
  border-radius: 0;
}

/*------icons-list---------*/

.example {
  padding: 1rem;
  border: 1px solid var(--border);
  font-size: 0.8175rem;
  border-radius: 7px;

  + .highlight {
    border-top: none;
    margin-top: 0;
  }
}

.text-wrap > :last-child {
  margin-bottom: 0;
}

.highlight {
  margin: 0 0 2rem;
  border: 1px solid #23241f;
  font-size: 0.9375rem;
  background: #edeff7;
  border-radius: 0 8px 7px 7px;
  position: relative !important;

  pre {
    margin-bottom: 0;
    background-color: #23241f;
    max-height: 20rem;
  }
}

pre {
  color: #3d3f4e;
  padding: 1rem;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #edeff7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -moz-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  border-top-right-radius: 7px;
}

/*------icons-list---------*/

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  transition: 0.3s background;
}

::-webkit-scrollbar-thumb {
  background: $bg-active;
}

*:hover::-webkit-scrollbar-thumb {
  background: #adb5bd;
}

@media (max-width: 568px) {
  .alert-dismissible .close {
    padding: 0.55rem 0.2rem;
    color: inherit;
  }

  .main-nav .nav-link + .nav-link {
    border-top: 0 solid var(--border);
  }

  .main-nav .nav-link + .nav-link {
    border-left: 0 !important;
  }
}

@media (max-width: 992px) {
  .responsive-logo .header-logo {
    display: block;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    position: absolute;
    top: 10px;
  }
}

.angle {
  color: #9ca2a9 !important;
}

.pro-img-box img {
  border: 1px solid var(--border) !important;
}

.nav-sub-item .sub-slide-item {
  color: var(--white);
}

.sub-menu li a:before {
  margin-right: 8px;
  width: inherit;
  height: inherit;
  border-width: 0;
  position: absolute;
  left: 8px;
  z-index: 99;
  border-color: #bec5d0;
  content: "\e92f";
  font-family: "feather" !important;
  top: 9px;
  font-size: 9px;
}

.slide.active.is-expanded {
  background: rgba(238, 238, 247, 0.5);
}

@media (max-width: 1134px) {
  .p-text {
    display: none;
  }
}

@media (max-width: 992px) {
  .fullscreen-button {
    display: none;
  }
}

.horizontalMenucontainer.main-header form[role="search"] {
  width: inherit;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // justify-content: center;
}

.prev-price {
  text-decoration: line-through;
}

.qunatity-list {
  margin-left: 0;
  padding-left: 6px;

  li {
    list-style-type: none;
  }
}

.feature .project {
  display: flex;
  width: 45px;
  height: 45px;
  line-height: 2em;
  vertical-align: middle;
  padding-top: 0;
  color: var(--white);
  font-size: 20px;
  border-radius: 50px;
  position: relative;
  align-items: center;
  text-align: center;
  left: 0;
  right: 0;
  justify-content: center;
  font-weight: 500;
}

.plan-icon {
  font-size: 25px;
  width: 80px;
  height: 80px;
  line-height: 80px !important;
  overflow: hidden;
  border: 1px solid #ebeaf1;
  border-radius: 50%;
  background: #f5f6fb;
  transition: all 0.3s;
  display: inline-block;
}

/*-----Back to Top-----*/

#back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  text-align: center;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  z-index: 1000;
  height: 50px;
  width: 50px;
  transition: background-color 0.1s linear;
  -moz-transition: background-color 0.1s linear;
  -webkit-transition: background-color 0.1s linear;
  -o-transition: background-color 0.1s linear;
  background: no-repeat center;
  color: var(--white);
  border-radius: 5px;

  i {
    padding-top: 0;
    font-size: 20px;
    line-height: 2.4;
  }
}

@media (max-width: 578px) {
  .main-logo {
    display: none !important;
  }
}

.box-shadow {
  box-shadow: none;
}

@media (max-width: 578px) {
  .desktop-logo.logo-light .main-logo {
    display: block;
  }
}

@media (max-width: 991px) {
  .icons-list-item {
    margin: 4px 4px;
  }
}
.chart-legend {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  z-index: 10;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;

  div {
    display: flex;
    align-items: center;

    + div {
      margin-left: 15px;
    }
  }

  span {
    width: 10px;
    height: 4px;
    display: block;
    margin-right: 5px;
  }
}

@media (min-width: 576px) {
  .chart-legend div + div {
    margin-left: 30px;
  }
}

@media (min-width: 576px) {
  .chart-legend span {
    width: 40px;
    margin-right: 10px;
  }
}

.chart-wrapper {
  position: relative;
  margin-left: -13px;
  margin-right: -13px;
}

@media (min-width: 576px) {
  .chart-wrapper {
    margin-left: -10px;
    margin-right: -15px;
  }
}

.main-img-user.online::after {
  background-color: $success;
}

.dropdown-menu-start {
  box-shadow: 0 0 15px 1px rgb(188, 181, 214);

  .dropdown-item {
    padding: 4px 19px;
  }

  img {
    width: 31px;
    height: 20px;
  }
}

.main-header-profile {
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
}

.dropdown-menu-arrow:after {
  content: "";
  position: absolute;
  top: -8.5px;
  right: 16px;
  border-bottom: 9px solid var(--white);
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
}

.dropdown-menu-start {
  right: 0 !important;
  left: auto !important;
  bottom: auto !important;
}

.customers .list-group-item .media img {
  width: 35px !important;
  height: 35px !important;
}

.list-group-item-action {
  width: 100%;
  color: #1d2635;
  text-align: inherit;
}

.progress-style {
  .table {
    th,
    td {
      padding: 0.75rem;
      vertical-align: middle;
    }
  }

  &.progress {
    overflow: visible !important;

    .progress-bar:after {
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 50%;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
      background: var(--white);
      position: absolute;
      right: -5px;
      top: -5px;
    }
  }

  .bg-primary-gradient.progress-bar:after,
  .bg-primary.progress-bar:after {
    border: 4px solid var(--primary-bg-color);
  }

  .bg-secondary.progress-bar:after {
    border: 4px solid $secondary;
  }

  .bg-danger.progress-bar:after {
    border: 4px solid $danger;
  }

  .bg-teal.progress-bar:after {
    border: 4px solid $teal;
  }

  .bg-pink.progress-bar:after {
    border: 4px solid $pink;
  }

  .bg-success.progress-bar:after {
    border: 4px solid $success;
  }

  .bg-danger-gradient.progress-bar:after {
    border: 4px solid $danger;
  }

  .bg-warning.progress-bar:after {
    border: 4px solid $warning;
  }

  .bg-info.progress-bar:after {
    border: 4px solid $info;
  }

  &.progress .progress-bar {
    box-shadow: none;
    border-radius: 0;
    position: relative;
    -webkit-animation: animate-positive 2s;
    animation: animate-positive 2s;
  }

  .progress-bar {
    float: left;
    height: 100%;
    font-size: 12px;
    border-radius: 20px !important;
    line-height: 20px;
    overflow: visible !important;
    color: var(--white);
    text-align: center;
    background-color: #337ab7;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
  }
}

.customers {
  .list-group-item-action {
    &:hover,
    &:focus {
      color: $default-color;
      background-color: var(--white);
    }
  }

  .list-group-item {
    padding: 0.82rem 1.25rem;
  }
}

.sales-info h3,
.card-table h4 {
  font-size: 21px;
  font-weight: 700;
  font-family: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #1c273c;
}

.header-icon-svgs {
  width: 20px;
  height: 20px;
  color: #7b8191;
  fill: #7b8191;
}

.fullscreen-button .full-screen-link {
  line-height: 33px !important;
}

@media (max-width: 991px) {
  .app-sidebar__toggle {
    text-align: center;
  }
}

@media (max-width: 920px) {
  .sidebar-mini {
    .breadcrumb-header {
      display: block !important;
    }
  }
}

@media (max-width: 576px) {
  .square-box {
    display: none;
  }
}

.counter-icon {
  margin-bottom: 0;
  display: inline-flex;
  padding: 1.3rem 1.4rem;
  border-radius: 50%;
  text-align: center;
  background: rgba(21, 22, 23, 0.2);

  i {
    color: var(--white);
    font-size: 22px;
  }
}

.success-widget h3 {
  &:before {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #ccead1;
  }

  &:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #ccead1;
    left: 0.5em;
  }

  &:before {
    right: 0.5em;
  }

  text-align: center;
}

.danger-widget h3 {
  &:before,
  &:after {
    background: #cad8a9;
  }

  &:before {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f5dce2;
  }

  &:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f5dce2;
    left: 0.5em;
  }

  &:before {
    right: 0.5em;
  }

  text-align: center;
}

.warning-widget h3 {
  &:before,
  &:after {
    background: #cad8a9;
  }

  &:before {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f3e7c6;
  }

  &:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #f3e7c6;
    left: 0.5em;
  }

  &:before {
    right: 0.5em;
  }

  text-align: center;
}

.info-widget h3 {
  &:before,
  &:after {
    background: $info;
  }

  &:before {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #bae4f5;
  }

  &:after {
    content: "";
    position: relative;
    display: inline-block;
    width: 26%;
    height: 1px;
    vertical-align: middle;
    background: #bae4f5;
    left: 0.5em;
  }

  &:before {
    right: 0.5em;
  }

  text-align: center;
}

.card .box {
  .img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
  }

  h2 {
    font-size: 20px;
    margin: 20px auto;
  }

  ul li a {
    display: block;
    margin: 0 10px;
    font-size: 20px;
    transition: 0.5s;
    text-align: center;
  }

  h2 span {
    background: var(--primary-bg-color);
    font-size: 14px;
    color: var(--white);
    display: inline-block;
    padding: 4px 10px;
    border-radius: 15px;
    margin-top: 10px;
  }

  position: relative;
  top: 50%;
  left: 0;
  text-align: center;
  padding: 30px;
  box-sizing: border-box;
  border: 1px solid #e9f1f1;
  border-radius: 4px;
  box-shadow: 0 0 25px var(--white)-3;
  width: 100%;
}

.dot-label {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  animation: ani 1s linear infinite;
  left: 7px;
  bottom: -12px;
  position: relative;
}

@keyframes ani {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.4);
  }

  50% {
    transform: scale(1.2);
  }

  70% {
    transform: scale(1.4);
  }

  90% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.search .page-link {
  background-color: var(--white);
}

.table {
  width: 100% !important;
  margin-bottom: 1rem;
  color: #22252f;
  border: 1px solid var(--border);
}

.main-logo1 {
  font-family: poppins;
  font-weight: 600;
}

.btn-outline-danger:focus {
  color: $danger;
}

.featured_icon i {
  transform: rotate(-43deg);
  position: absolute;
  top: 3px;
  text-align: center;
  right: 0;
  left: 0;
  color: var(--white);
  font-size: 6px;
}

.fa-circle:before {
  content: "\f111";
}

.featured_icon.danger {
  border: 3px solid #ee6161;
  background-image: linear-gradient(-38deg, #f32828 0%, #f32828 100%) !important;
}

.featured_icon {
  width: 17px;
  height: 17px;
  line-height: 60px;
  margin-left: -35px !important;
  background-image: linear-gradient(-38deg, var(--primary-bg-color) 0%, #6922be 100%);
  color: var(--black);
  font-size: 10px;
  border: 3px solid #a36de3;
  position: relative;
  margin-top: -40px;
  border-radius: 18px;
  top: 20px;
}

ul.timeline:before {
  content: " ";
  background: var(--border);
  display: inline-block;
  position: absolute;
  left: 25px;
  width: 1.5px;
  height: 76%;
  z-index: 0;
  top: 61px;
}

.latest-timeline {
  .timeline {
    li.activity {
      margin-bottom: 2.5rem;
      padding: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.featured_icon.success {
  border: 3px solid #25de8c;
  background-image: linear-gradient(-38deg, #0ba360 0%, #3cba92 100%) !important;
}

.featured_icon.warning {
  border: 3px solid #f09819;
  background-image: linear-gradient(-38deg, #ff5858 0%, #f09819 100%) !important;
}

.featured_icon.teal {
  border: 3px solid $teal;
  background-image: linear-gradient(-38deg, #265b5b 0%, $teal 100%) !important;
}

.progress-style .bg-warning-gradient.progress-bar:after {
  border: 4px solid #f67536;
}

.fa-genderless:before {
  content: "\f22d";
}

.fs-12 {
  font-size: 12px;
}

.fs-15 {
  font-size: 15px;
}

.btn-icon {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
}

.country-card {
  padding: 20px;
}

@media (max-width: 991px) {
  .responsive-navbar.navbar .navbar-collapse {
    padding: 0 5px;
    width: 100%;
    background: #fff;
    margin-top: 0;
    z-index: 9999;
    top: 63px;
    border-bottom: 1px solid #f0f0ff;
    border-top: 1px solid #f0f0ff;
    position: fixed;
    left: 0;
    right: 0;
  }

  .responsive-navbar.navbar {
    position: relative !important;
    display: -ms-flexbox;
    display: flex;
    top: -5px;
    margin: 0;
    padding: 0;

    .new.nav-link {
      margin: 10px 3.5px !important;
    }
  }

  .responsive-logo {
    .mobile-logo {
      margin-top: 3px !important;
    }
  }
}

@media (min-width: 992px) {
  .navbar-toggler.navresponsive-toggler {
    display: none;
  }
}

.feature-1 {
  position: absolute;
  right: 40px;
  top: 30px;
}

.feature .text-start .text-success {
  background: #c1ecab;
  padding: 10px;
  border-radius: 10px;
  margin-left: -104px;
}

.feature .text-danger {
  background: #fba5b9;
  padding: 10px;
  border-radius: 10px;
  margin-left: -104px;
}

.sidenav-toggled .responsive-navbar.navbar .navbar-collapse {
  z-index: 999 !important;
}

.product-grid6 .icons li a {
  color: var(--white);
}

.product-grid6 .icons {
  padding: 0;
  list-style: none;
  opacity: 0;
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  bottom: -3px;
  z-index: 1;
  transition: all 0.5s ease 0s;
  display: block;
  margin: 0 auto;
}

.product-grid6 .icons li .primary-gradient {
  font-size: 13px;
  line-height: 35px;
  text-align: center;
  height: 35px;
  width: 35px;
  margin: 0 auto;
  border-radius: 4px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  background: var(--primary-bg-color)-gradient;
  color: var(--white);
}

.product-grid6 .icons li .secondary-gradient {
  font-size: 13px;
  line-height: 35px;
  text-align: center;
  height: 35px;
  width: 35px;
  margin: 0 auto;
  border-radius: 4px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  background: $secondary-gradient;
}

.product-grid6 .icons li .info-gradient {
  font-size: 13px;
  line-height: 35px;
  text-align: center;
  height: 35px;
  width: 35px;
  margin: 0 auto;
  border-radius: 4px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  background: $info-gradient;
}

.product-grid6:hover .icons {
  opacity: 1;
  bottom: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.product-grid6 .icons li a:hover:after,
.product-grid6 .icons li a:hover:before {
  opacity: 1;
}

.product-image .pic-1 {
  transition: all 0.3s ease;
}

.product-grid6 .product-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.product-image .pic-2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
  bottom: 0;
  right: 0;
  margin: 0 auto;
}

.product-grid6 .product-link a:nth-child(2n) {
  border-right: none;
}

.product-grid6 .product-image a.image {
  display: block;
}

.product-grid6:hover .product-image .pic-2 {
  opacity: 0;
}

.product-grid6:hover .icons li {
  opacity: 1;
}

.product-grid6 .icons li {
  margin: 0 auto;
  display: inline-block;
  opacity: 0;
  transition: all 0.4s ease;
}

.handle-counter {
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.counter-minus.btn {
  padding: 9px 15px !important;
}

.handle-counter .btn {
  padding: 9px 10px !important;
}

.handle-counter input {
  float: left;
  text-align: center;
  height: 41px;
  border-radius: 0;
  width: 70px;
  border: 1px solid var(--border);
  outline: none;
  border-left: 0;
  border-right: 0;
}

.handle-counter {
  .counter-minus,
  .handle-counter .counter-plus {
    float: left;
    text-align: center;
    border-radius: 1px;
  }

  .counter-minus {
    border-radius: 3px 0 0 3px !important;
  }

  .counter-plus {
    border-radius: 0 3px 3px 0 !important;
  }
}

.counter-minus {
  border-radius: 0 0 0 0 !important;
}

.counter-plus {
  border-radius: 0 !important;
}

.file-image-1 {
  width: 100px;
  height: 100px;
  display: inline-table;
  margin: 20px;
  position: relative;
  border: 1px solid var(--border);
  border-radius: 5px;
}

.file-image-1 .product-image img {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
}

.file-image-md .product-image img {
  width: 150px;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
}

.file-image-lg .product-image img {
  width: 200px;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
}

.file-image-1 .icons {
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  right: 7px;
  top: 6px;
  position: absolute;
  bottom: 0;
  z-index: 1;
  transition: all 0.5s ease 0s;
  text-align: right;
}

.file-image-1:hover .icons {
  opacity: 1;
  bottom: 33px;
}

.file-image-1 .icons li a {
  font-size: 12px;
  line-height: 28px;
  text-align: center;
  height: 25px;
  width: 25px;
  margin: 4px 1px;
  padding: 0;
  border-radius: 50px !important;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  color: var(--white);
}

.file-image-1 .file-name-1 {
  position: absolute;
  right: 0;
  left: 0;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
}

//File image-2 styles

.file-image-md {
  width: 150px;
  height: 150px;
}

.file-image-md .icons li a {
  font-size: 13px;
  line-height: 30px;
  height: 28px;
  width: 28px;
}

//File image-2 styles

.file-image-lg {
  width: 200px;
  height: 200px;
}

.file-image-lg .icons li a {
  font-size: 15px;
  line-height: 35px;
  height: 35px;
  width: 35px;
}

.edit {
  display: inline-block;
  color: var(--primary-bg-color);
  font-size: 18px;
  line-height: 1;
  text-decoration: none;
  margin-left: 30px;
}

.dlist-align {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.card-options a:not(.btn) {
  margin-left: 0;
  color: #505069;
  display: inline-block;
  min-width: 1rem;
  padding: 0 15px;
}

.card-options {
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-order: 100;
  order: 100;
  margin-right: -0.5rem;
  color: #a8afc7;
  align-self: center;
}

.product-carousel .carousel-inner .carousel-item {
  border: 0 solid var(--border);
  padding: 35px;
  border-radius: 5px;
  background: transparent;
}

.carousel-inner .carousel-item .thumb {
  padding: 0.55rem;
  border: 1px solid var(--border);
  border-radius: 5px;
}

.carousel-inner .carousel-item .thumb.active {
  border: 1px solid var(--border);
  background: $bg-active;
}

/* ######  Loader ###### */

/* ###### circleloader ###### */

.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}

.lds-circle > div {
  background: var(--primary-bg-color);
}

.lds-circle > div {
  display: inline-block;
  width: 51px;
  height: 51px;
  margin: 6px;
  border-radius: 50%;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes lds-circle {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }

  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }

  100% {
    transform: rotateY(3600deg);
  }
}

/* ###### dual-ring loader ###### */

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  border: 5px solid var(--primary-bg-color);
  border-color: var(--primary-bg-color) transparent var(--primary-bg-color) transparent;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  animation: lds-dual-ring 1.2s linear infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* ###### heart loader ###### */

.lds-heart {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  transform: rotate(45deg);
  transform-origin: 32px 32px;
}

.lds-heart div {
  background: var(--primary-bg-color);
}

.lds-heart div:after,
.lds-heart div:before,
.lds-heart div:after,
.lds-heart div:before {
  background: var(--primary-bg-color);
}

.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  left: -17px;
  border-radius: 50% 0 0 50%;
}

.lds-heart div:after,
.lds-heart div:before,
.lds-heart div:after,
.lds-heart div:before {
  background: var(--primary-bg-color);
}

.lds-heart div:after {
  top: -17px;
  border-radius: 50% 50% 0 0;
}

.lds-heart div:after {
  content: " ";
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
}

.lds-heart div:after {
  content: " ";
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
}

.lds-heart div {
  background: var(--primary-bg-color);
}

.lds-heart div {
  top: 23px;
  left: 19px;
  position: absolute;
  width: 26px;
  height: 26px;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }

  5% {
    transform: scale(1.1);
  }

  39% {
    transform: scale(0.85);
  }

  45% {
    transform: scale(1);
  }

  60% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(0.9);
  }
}

/* ###### ripple loader ###### */

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ripple div {
  border: 4px solid var(--primary-bg-color);
}

.lds-ripple div {
  position: absolute;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div {
  position: absolute;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

/* ###### Spinner loader ###### */

.lds-spinner {
  color: initial;
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div {
  transform-origin: 32px 32px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:after {
  background: var(--primary-bg-color);
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 29px;
  width: 5px;
  height: 14px;
  border-radius: 20%;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* ######  lines loader  ###### */

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-facebook div:nth-child(1) {
  left: 6px;
  animation-delay: -0.24s;
}

.lds-facebook div {
  background: var(--primary-bg-color);
}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(2) {
  left: 26px;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
  left: 45px;
  animation-delay: -0.12s;
}

@keyframes lds-facebook {
  0% {
    top: 6px;
    height: 51px;
  }

  50%,
  100% {
    top: 19px;
    height: 26px;
  }
}

.Accordion-Style02 {
  border-radius: 0;
}

@media (max-width: 991.98px) {
  .main-header-message .dropdown-menu,
  .main-header-notification .dropdown-menu,
  .main-profile-menu .dropdown-menu {
    width: 95% !important;
    left: 5px !important;
    right: 5px !important;
    top: 60px !important;
    margin: 0 auto;
  }

  .menu-header-content {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .main-header-message,
  .main-header-notification,
  .main-profile-menu,
  .nav-link.icon {
    position: inherit !important;
  }

  .main-header-profile {
    border-radius: 0;
  }
}

.btn.Sub {
  padding: 5px 20px !important;
  transition: none;
}

.bxl-instagram.tx-prime,
.bxl-linkedin.tx-prime,
.bxl-twitter.tx-prime,
.bxl-facebook.tx-prime {
  color: var(--primary-bg-color);
  display: block;
  font-size: 22px;
  position: relative;
  line-height: 35px !important;
  outline: none;
  height: 37px;
  width: 37px;
  background: var(--white);
  margin: auto;
  text-align: center;
  border: 1px solid #f1ecf7;
  box-shadow: none;
  border-radius: 35px;
}

@media (max-width: 568px) {
  .feature-1 {
    right: 4px;
  }

  .btn,
  .sp-container button {
    padding: 0.5rem 0.5rem;
    transition: none;
    margin-right: 0;
    margin-top: 0;
  }

  .main-contact-action {
    right: 0 !important;
  }
}

.main-sidemenu .slide.is-expanded i.angle {
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: absolute;
  top: 13px;
  right: 20px;
  font-size: 15px;
}

.main-sidemenu i.angle {
  -webkit-transform-origin: center;
  transform-origin: center;
  position: absolute;
  top: 13px;
  right: 20px;
  font-size: 15px;
}

@media (max-width: 600px) {
  .main-nav-line .nav-link + .nav-link {
    margin-top: 13px;
    margin-left: 0;
  }
}

@media (max-width: 576px) {
  .nav.main-nav-line {
    display: block;
  }
}

@media (max-width: 991px) {
  .main-error-wrapper.wrapper-1 {
    margin-top: 0 !important;
  }

  .profile-cover__info .nav li {
    width: 100% !important;
  }

  .profile-cover__info .nav li:not(:first-child) {
    margin-left: 0 !important;
  }
}

@media (max-width: 568px) {
  .btn.btn-rounded.plus,
  .btn.btn-rounded.comment {
    padding: 8px 12px;
    transition: none;
    margin-right: -25px;
    margin-top: 9px;
  }

  .main-error-wrapper h1 {
    line-height: 1;
    font-size: 95px !important;
  }
}

.mail-option .chk-all,
.mail-option .btn-group a.btn {
  border: 1px solid #ebecf1;
  border-radius: 3px !important;
  display: inline-block;
  min-height: 0;
  padding: 5px 10px;
}

ul.inbox-pagination {
  float: right;
  list-style-type: none;
  display: flex;
}

.mail-option .dropdown-menu > li > a {
  display: block;
  padding: 0.5rem 0.75rem;
  clear: both;
  font-weight: 400;
  line-height: 1.428571429;
  color: #7987a1;
  white-space: nowrap;
}

.inbox-pagination a.np-btn {
  border: 1px solid #ebecf1;
  border-radius: 3px !important;
  display: inline-block;
  padding: 5px 10px;
  margin-left: 5px;
  vertical-align: middle;
  min-height: 0;
}

.inbox-pagination li span {
  display: inline-block;
  margin-right: 5px;
  margin-top: 10px;
}

.remove-icons .remove-heart i {
  border: 1px solid var(--primary-bg-color);
  border-radius: 35px;
  padding: 6px;
  color: var(--primary-bg-color);
}

.remove-icons .remove-thumb i {
  border: 1px solid $secondary;
  border-radius: 35px;
  padding: 6px;
  color: $secondary;
}

.nav.panel-tabs .side-menu__icon {
  margin-right: 5px;
  width: 23px;
  height: 23px;
  color: #7987a1;
  fill: #7987a1;
}

.nav.panel-tabs .active .side-menu__icon {
  fill: #fff;
}

hr:not([size]) {
  height: 0;
}

ul.timeline li {
  list-style: none !important;
}

.btn-check:focus + .btn-info,
.btn-info:focus {
  color: #fff;
}

.light-layout {
  display: none;
}

@media (max-width: 991px) {
  .header-icons .new.nav-link {
    position: relative;
    margin: auto 6px !important;
  }

  .responsive-navbar.navbar .dropdown {
    position: initial;
  }
}

@media (max-width: 992px) {
  .responsive-navbar .collapse.show {
    .dropdown {
      position: inherit;

      .dropdown-menu {
        &.header-search {
          width: 100%;
          left: 0;
          right: 0;

          .form-control {
            border-radius: 5px;
          }
        }

        &:before,
        &:after {
          display: none;
        }
      }

      .show.dropdown-menu {
        top: 57px !important;
        left: 5px !important;
        right: 5px !important;
      }
    }
  }
}

@media (width: 992px) {
  .main-header-message .dropdown-menu {
    width: 16rem !important;
  }
}

.header-brand .desktop-dark {
  display: none;
}

.responsive-logo .mobile-logo.dark-logo-1 {
  display: none;
}

hr {
  border-top: 1px solid var(--border);
}

.circle-icon.widget {
  i {
    line-height: 1.9 !important;
  }
}

.circle-icon {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 40px;
  border-radius: 5px;
  right: 40px;

  i {
    line-height: 2.4 !important;
  }
}

.lh-lg {
  line-height: 2.2 !important;
}

.chart-dropshadow {
  -webkit-filter: drop-shadow(-6px 12px 4px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(-6px 12px 4px rgba(0, 0, 0, 0.1));
}

@media (min-width: 992px) {
  .main-content .main-header .container-fluid {
    padding: 0;
  }
}

@media (max-width: 991px) {
  form[role="search"].active input {
    top: 0.09rem !important;
  }

  .navbar-form.active .input-group-btn {
    top: 1rem !important;
  }

  .demo-icon .nav-link {
    padding: 12px 4px 4px 10px !important;
  }
}

@media (min-width: 992px) and (max-width: 992px) {
  .search-open {
    .main-header {
      z-index: 9999 !important;
    }

    form[role="search"].active {
      position: fixed;
      top: 0;

      input {
        top: 0;
        transition: none;
        box-shadow: none;
      }

      .input-group-btn {
        top: 0.75rem;
      }
    }
  }

  .main-header-right .input-group-btn .btn {
    height: 30px !important;
    width: 30px !important;
    line-height: 18px !important;
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

.row.user-social-detail {
  position: absolute;
}

.pos-absolute.bg-black-9 {
  border-radius: 0 5px 5px 0;
}

.main-mail-star .typcn-star:before {
  content: "\e109";
  font-size: 20px;
  line-height: 0.8;
}

.main-content-label {
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 10px;
  letter-spacing: 0.2px;
}

#morrisDonut1.morris-donut-wrapper-demo svg text {
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
}
#morrisDonut2.morris-donut-wrapper-demo svg text {
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
}

.form-label {
  display: block;
  margin-bottom: 0.375rem;
  font-weight: 500;
  font-size: 0.875rem;
  margin-top: 9px;
}

.form-group {
  margin-bottom: 1rem;
  display: block;
}

div.dt-button-info {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  margin-top: -100px;
  margin-left: -200px;
  background-color: white;
  border: 1px solid #ededf5;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  text-align: center;
  z-index: 21;
}

div.dt-button-info h2 {
  padding: 0.5em;
  margin: 0;
  font-weight: normal;
  border-bottom: 1px solid #ddd;
  background-color: #f4f4fb;
  font-size: 22px;
}

.file-manger-icon img {
  width: 70px;
  height: 70px;
}

.file-manger-icon {
  position: relative;
}

@media (min-width: 992px) {
  .main-footer {
    padding: 0.9rem 1rem 0.9rem 250px !important;
  }
}

@media (max-width: 992px) {
  .main-footer {
    padding: 0.9rem 1rem 0.9rem 10px;
  }
}

@media (max-width: 991px) {
  .handle-counter input {
    height: 41px;
    width: 48px;
  }

  .handle-counter .btn {
    padding: 5px 6px !important;
  }
}

.item7-card-img {
  border-radius: 5px !important;
}

@media (max-width: 320px) {
  .sweet-alert button {
    padding: 0.45rem 0.5rem;
  }
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

.bootstrap-tagsinput .badge [data-role="remove"]:after {
  content: "×";
  margin-left: 6px;
}

.btn-close {
  box-sizing: content-box;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
  padding: 5px;
  line-height: 1;
  font-size: 24px;
  background-image: none;
  color: #000;

  &:focus {
    box-shadow: none !important;
  }
}

@media (max-width: 576px) {
  .alert {
    padding: 12px 30px;
  }

  .intl-tel-input input {
    width: 180px;
  }
}

.typcn:before {
  height: auto !important;
  line-height: 2 !important;
}

.demo-icon {
  margin: auto 6px !important;
  margin-right: 20px !important;
}

.search-icon {
  margin: auto 6px !important;
}

.file-manager-icon {
  width: 28px;
  height: 28px;
}

.pricing-body {
  list-style-type: none;
}

.pricing-card {
  transition: box-shadow 0.3s;
}

.pricing-card:hover {
  box-shadow: 0 16px 32px rgba(33, 33, 33, 0.15);
}

.border-primary-transparent {
  border: 1px solid #e8e7fe;
}

.pricing-tabs ul.nav-price {
  text-align: center;
  display: inline-flex;
  margin: 30px auto 40px;
  border: 1px solid #e6ebf1;
  border-radius: 5px;
  background: #fff;
  overflow: hidden;
}

.pricing-tabs ul.nav-price li {
  display: block;
}

.pricing-tabs ul.nav-price li a.active {
  background: var(--primary-bg-color);
}

.pricing-tabs ul.nav-price li a.active {
  color: #fff;
  transition: 0.3s;
}

.pricing-tabs ul.nav-price li a {
  padding: 12px 22px;
  display: block;
  background: #fff;
  font-size: 16px;
  border-radius: 0;
  transition: 0.3s;
}

// userlist data table

#user-datatable {
  // border-left: 0px !important;

  thead th {
    background-image: none;
    background-color: #ffffff !important;
    position: relative;
    // border-right: 0px !important;
    // border-left: 0px !important;
  }

  td {
    padding: 0.8rem;
    // border-right: 0px !important;
    // border-left: 0px !important;
  }

  thead .sorting::after {
    content: "\f3d0" !important;
    top: 49%;
    display: none !important;
  }

  input.form-control {
    width: 25rem !important;
    border-radius: 4px !important;
    background-color: rgba(118, 109, 249, 0.13) !important;
  }
}

.demo-icon.nav-link {
  padding: 0 0 0 0.2rem !important;
}

.theme-layout {
  cursor: pointer;
}

.file-manger-icon1 img {
  width: 70px;
  height: 70px;
  border-radius: 10px;
}

.intl-tel-input .flag-dropdown .selected-flag {
  padding: 10px 18px 9px 7px;
}

.lg-outer .lg-thumb-item {
  border: 0 solid #fff !important;
}

.form-control::placeholder {
  opacity: 0.7;
}

.white-space-nowrap {
  white-space: nowrap;
}

//tag attachments css//

.attached-file-grid6 .icons li {
  margin: 0 auto;
  display: inline-block;
  opacity: 0;
  transition: all 0.4s ease;
}

.attached-file-grid6 .icons li a:hover {
  color: #fff;
}

.attached-file-grid6 .icons li a:hover {
  border: var(--primary-bg-color);
  background: var(--primary-bg-color);
}

.attached-file-grid6 .file-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.attached-file-image .pic-1 {
  transition: all 0.3s ease;
}

.attached-file-image .pic-2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease;
  bottom: 0;
  right: 0;
  margin: 0 auto;
}

.attached-file-grid6:hover .attached-file-image .pic-2 {
  opacity: 0;
}

.attached-file-grid6:hover .icons {
  opacity: 1;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
}

.attached-file-grid6:hover .icons li {
  opacity: 1;
}

.image-pic {
  position: absolute;
  right: 0;
  left: 5px;
  top: 10px;
  color: #fff;
  font-size: 15px;
  // background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent 100%);
  padding: 0 0 20px 10px;
  border-radius: 5px;
}

.tag.tag-attachments {
  padding: 4px 15px 2px 15px;
  font-size: 13px;
  background-color: #ffffff;
  border: 1px solid #e8e8f7;
}

.tag.tag-attachments-lg {
  padding: 5px 17px 3px 17px;
  font-size: 17px;
  background-color: #ffffff;
  border: 1px solid #e8e8f7;
}

.tag.tag-attachments-sm {
  padding: 3px 10px 1px 10px;
  font-size: 13px;
  background-color: #ffffff;
  border: 1px solid #e8e8f7;
}

.tags .tag-files span {
  position: absolute;
  top: 21px;
  margin-right: 104px;
  left: 51px;
  font-size: 12px;
  color: #a5b1d9;
}

.attached-file-grid6 .icons li a {
  font-size: 13px;
  line-height: 30px;
  text-align: center;
  height: 30px;
  width: 30px;
  margin: 0 auto;
  border-radius: 5px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  background: #fff;
  padding: 0;
}

.attached-file-grid6 .icons li a {
  color: var(--primary-bg-color);
  border: 1px solid #fff;
}

.attached-file-grid6 .icons {
  padding: 0;
  list-style: none;
  opacity: 0;
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  bottom: -3px;
  z-index: 1;
  transition: all 0.5s ease 0s;
  display: block;
  margin: 0 auto;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent 100%);
}

.pro-img-box {
  position: relative;
}

.theme-container {
  button {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    padding: 5px 10px;
    background-color: var(--white);
    border: 1px solid var(--border);
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 5px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
}

.theme-container1 {
  button {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    padding: 5px 10px;
    background-color: var(--white);
    border: 1px solid var(--border);
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 5px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
}

.theme-container2 {
  button {
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    padding: 5px 10px;
    background-color: var(--white);
    border: 1px solid var(--border);
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 5px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
}

.pickr {
  text-align: center;
  margin-top: 10px;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background-color: var(--white);
  opacity: 7;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-nav .owl-next {
  position: absolute;
  top: 50%;
  right: -25px;
  margin-top: -1.65em;
}

.owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  left: -25px;
  margin-top: -1.65em;
}

.owl-nav button {
  display: block;
  font-size: 1.3rem !important;
  line-height: 2em;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  text-align: center;
  background: var(--white) !important;
  opacity: 0.5 !important;
  border: 1px solid #e8ebf3 !important;
  z-index: 99;
  box-shadow: 0 4px 15px rgba(67, 67, 67, 0.15);
}

@media (min-width: 992px) {
  .main-settings-menu .nav-link {
    font-size: 14px;

    i {
      font-size: 18px;
    }
  }
}

.settings-main-icon {
  display: flex;
  text-align: center;

  i {
    width: 3rem;
    font-size: 18px;
    line-height: 3rem;
    height: 3rem;
    background-color: var(--primary-bg-color)-02;
    color: var(--primary-bg-color);
    border-radius: 50%;
  }
}

// about us //
.leading-normal {
  line-height: 1.5 !important;
}

.lead-1 {
  font-size: 1.6rem;
  font-weight: 500;
}

.aboutlist {
  list-style-type: none;
}

.about-icons {
  height: 60px;
  width: 60px;
}

.about-team {
  width: 80px;
  height: 80px;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.about-main,
.about-motto {
  display: flex;
  align-items: center;
  justify-content: center;
}

.motto-icon {
  width: 30px;
  height: 30px;
}

//!about us//

.prime-card {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 160px;
    transform: scale(1.9) translate(3px, -12px);
  }
}

@media (max-width: 1600px) {
  .prime-card {
    img {
      height: 250px;
      transform: scale(1);
    }
  }
}

.apexcharts-yaxis-texts-g text {
  fill: #adb5be !important;
}

.apexcharts-xaxis-texts-g text {
  fill: #adb5be !important;
}

#sales {
  justify-content: center;
  align-items: center;

  div {
    margin: 0 auto;
  }
}

#Viewers {
  .apexcharts-canvas {
    margin: 0 auto;
  }
}

.apexcharts-legend-marker {
  margin-right: 10px !important;
}

.apexcharts-legend-series {
  margin: 0 20px !important;
}

.under-countdown .countdown {
  padding: 20px;
  border-radius: 5px;
}

.countdown span:first-child {
  font-size: 30px;
  font-weight: 500;
}

//Notifications//

.notification {
  list-style-type: none;
  padding: 0;
  position: relative;
}

.notification:before {
  content: "";
  position: absolute;
  top: 40px;
  bottom: 5px;
  width: 4px;
  background-color: var(--primary-bg-color)-02;
  left: 20%;
  margin-left: -2.5px;
}

.notification > li {
  position: relative;
  min-height: 50px;
  padding: 15px 0;
}

.notification .notification-time {
  position: absolute;
  left: 0;
  width: 18%;
  text-align: right;
  top: 30px;
}

.notification .notification-time .date,
.notification .notification-time .time {
  display: block;
  font-weight: 500;
}

.notification .notification-time .date {
  line-height: 16px;
  font-size: 11px;
  margin-bottom: 4px;
  color: #7987a1;
}

.notification .notification-time .time {
  line-height: 24px;
  font-size: 18px;
  color: #7987a1;
}

.notification .notification-icon {
  left: 15%;
  position: absolute;
  width: 10%;
  text-align: center;
  top: 51px;
}

.notification .notification-icon a {
  text-decoration: none;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  line-height: 10px;
  color: #fff;
  font-size: 14px;
  border: 3px solid var(--primary-bg-color);
  transition: border-color 0.2s linear;
}

.notification .notification-body {
  box-shadow: 0 1px 16px rgba(0, 0, 0, 0.05);
  margin-left: 24%;
  margin-right: 18%;
  background: #fff;
  position: relative;
  padding: 14px 20px;
  border-radius: 6px;
}

.notification .notification-body:before {
  content: "";
  display: block;
  position: absolute;
  border: 10px solid transparent;
  border-right-color: var(--white);
  left: -20px;
  top: 32px;
}

.notification .notification-body > div + div {
  margin-top: 15px;
}

.notification-badge {
  background-color: #eff1f5;
  color: #7987a1;
}

@media (max-width: 576px) {
  .notification .notification-body:before {
    display: none;
  }

  .notification .notification-icon a {
    display: none;
  }

  .notification:before {
    display: none;
  }

  .notification-body {
    .media {
      flex-direction: column;

      .main-img-user {
        margin-bottom: 10px !important;
      }
    }
  }

  .notification .notification-time {
    z-index: 99;
    width: 100%;
    right: 5px !important;
    position: absolute;
    top: 20px !important;
  }

  .notification .notification-body {
    margin-left: 0;
    margin-right: 0;
    position: relative;
  }

  .notification-badge {
    position: absolute;
    left: 10px;
    top: 8px;
  }

  .notification .notification-time .date,
  .notification .notification-time .time {
    display: inline;
  }

  .notification .notification-time .time {
    line-height: 16px;
    font-size: 11px;
    margin-left: 5px;
    margin-right: 10px;
    color: #b6bfcf;
  }
}

//End-Notifications//

//Country selector modal//

.country-selector img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

#country-selector {
  .modal-header {
    background-color: rgba($dark, 0.1);
    border-bottom: 1px solid #dae5e7 !important;
  }

  .modal-body {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    &:before {
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      background-color: rgba($dark, 0.1);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}

.btn-country {
  box-shadow: none !important;
  text-align: justify !important;
  font-size: 13px !important;
  line-height: 1;
  border-radius: 12em;
  padding: 0.45rem 0.45rem !important;
  transition: none !important;

  &:hover {
    border: 1px solid var(--primary-bg-color) !important;
  }

  .country-selector {
    img {
      box-shadow: 0 1px 16px rgba(0, 0, 0, 0.2) !important;
    }
  }
}

.btn-check:checked + .btn-country,
.btn-country.active,
.btn-country:active {
  border: 1px solid var(--primary-bg-color) !important;
  font-weight: 600;
  color: var(--black);
  background-color: #fff;
}

//Country selector modal//

#login-otp {
  display: none;
}

.apexcharts-toolbar {
  z-index: 1 !important;
}

.app-sidebar.sidebar-scroll.open.ps--scrolling-y {
  .main-content.app-content {
    position: fixed !important;
  }
}

.apexcharts-legend .apexcharts-legend-series .apexcharts-legend-text {
  font-size: 14px !important;
  font-weight: 500 !important;
}

//____________For Iphone

@supports (-webkit-touch-callout: none) {
  @media screen and (max-device-width: 991px) and (orientation: portrait) {
    .ps {
      &.ps--active-y {
        &:hover > .ps__rail-y,
        &:focus > .ps__rail-y {
          opacity: 0;
        }
      }
    }
  }

  @media screen and (max-device-width: 991px) and (orientation: landscape) {
    .ps {
      &.ps--active-y {
        &:hover > .ps__rail-y,
        &:focus > .ps__rail-y {
          opacity: 0;
        }
      }
    }
  }
}

.main-rocket {
  fill: var(--primary-bg-color);
}

// Plugin primary colors//

.tree li i {
  color: var(--primary-bg-color);
}

.bootstrap-tagsinput .badge {
  background-color: var(--primary-bg-color);
  border: 1px solid var(--primary-bg-color)-border;
}

.sweet-alert button {
  background-color: var(--primary-bg-color) !important;
}

// Quill editor styles //

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--primary-bg-color);
}

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: var(--primary-bg-color);
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--primary-bg-color);
}

.ql-snow a {
  color: var(--primary-bg-color);
}

// !Quill editor styles //

// gallery //

.lg-actions .lg-next,
.lg-actions .lg-prev {
  background-color: var(--primary-bg-color)-05;
  border: 1px solid var(--primary-bg-color);
}

.lg-toolbar {
  background-color: var(--primary-bg-color)-05;
}

//  !gallery //

// Bootstrap datepicker //
.datepicker .datepicker-switch {
  color: var(--primary-bg-color);
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  color: var(--primary-bg-color) !important;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  color: var(--primary-bg-color);
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-image: -moz-linear-gradient(to bottom, var(--primary-bg-color), var(--primary-bg-color));
  background-image: -ms-linear-gradient(to bottom, var(--primary-bg-color), var(--primary-bg-color));
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(var(--primary-bg-color)), to(var(--primary-bg-color)));
  background-image: -webkit-linear-gradient(to bottom, var(--primary-bg-color), var(--primary-bg-color));
  background-image: -o-linear-gradient(to bottom, var(--primary-bg-color), var(--primary-bg-color));
  background-image: linear-gradient(to bottom, var(--primary-bg-color), var(--primary-bg-color));
  background-repeat: repeat-x;
  filter: gradient(startColorstr='var(--primary-bg-color)', endColorstr='var(--primary-bg-color)', GradientType=0);
  border-color: var(--primary-bg-color);
}

.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background: var(--primary-bg-color);
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: var(--primary-bg-color);
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-image: -moz-linear-gradient(to bottom, var(--primary-bg-color), var(--primary-bg-color));
  background-image: -ms-linear-gradient(to bottom, var(--primary-bg-color), var(--primary-bg-color));
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(var(--primary-bg-color)), to(var(--primary-bg-color)));
  background-image: -webkit-linear-gradient(to bottom, var(--primary-bg-color), var(--primary-bg-color));
  background-image: -o-linear-gradient(to bottom, var(--primary-bg-color), var(--primary-bg-color));
  background-image: linear-gradient(to bottom, var(--primary-bg-color), var(--primary-bg-color));
  filter: gradient(startColorstr='var(--primary-bg-color)', endColorstr='var(--primary-bg-color)', GradientType=0);
  border-color: var(--primary-bg-color);
}

.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: var(--primary-bg-color);
}

.datepicker table tr td span.old:hover,
.datepicker table tr td span.new:hover {
  color: var(--white) !important;
}

// !Bootstrap datepicker //

// !Plugin primary colors//

@media (max-width: 991.98px) {
  .error-page1 {
    .demo-icon {
      svg {
        position: absolute;
        left: auto;
        right: 60px;
        top: 60px;
        fill: var(--primary-bg-color);
        width: 25px;
        height: 25px;
        z-index: 99999;
      }
    }
  }
}

.error-page1 {
  &.dark-theme .main-card-signin {
    box-shadow: none !important;
  }

  &.dark-theme .main-card-signin {
    background-color: transparent;
    border: 1px solid transparent;
  }

  .demo-icon {
    svg {
      position: absolute;
      left: auto;
      right: 60px;
      top: 60px;
      fill: var(--white);
      width: 25px;
      height: 25px;
    }
  }

  .tabs-menu1 ul li a.active {
    border-bottom: 3px solid var(--primary-bg-color) !important;
  }

  .tabs-menu1 ul li a {
    border-bottom: 3px solid #e6ebf1 !important;
  }
}

@media (max-width: 991.98px) {
  .error-page1 .demo-icon svg {
    position: absolute;
    left: auto;
    right: 50px;
    top: 20px;
    width: 25px;
    z-index: 999;
    height: 25px;
  }
}

.carousel-inner.slider {
  position: relative;
  height: 400px;

  .carousel-item {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.sidebar-mini {
  .slide-left,
  .slide-right {
    display: none !important;
  }
}

#videomodal,
#audiomodal {
  .modal-content {
    box-shadow: 0 1px 15px 1px rgba(156, 156, 168, 0.5);
  }
}

.main-content-app {
  .option-dots {
    position: relative;
    right: 15px;
    z-index: 999;
    margin: 0 auto;
  }
}

// Gallery styles //

.masonry {
  .brick {
    width: auto;
    margin-bottom: 20px;
  }
}

.brick img {
  border-radius: 5px;
}

.smartphoto-img.active {
  border-radius: 5px;
}

.smartphoto-arrows li {
  padding: 8px;
  border-radius: 5px;
  background: var(--primary-bg-color)-02;
}

.smartphoto-arrows a {
  width: 50% !important;
  height: 50% !important;
  margin: 7px;
}

.smartphoto-dismiss {
  width: 15px !important;
  height: 15px !important;
  right: 22px !important;
  top: 18px !important;
}

.smartphoto {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.smartphoto-arrow-left {
  left: 15px !important;
}

.smartphoto-arrow-right {
  right: 15px !important;
}

.smartphoto-nav li {
  width: 70px !important;
  height: 70px !important;
  margin-left: 5px;
}

.smartphoto-nav {
  bottom: 10px !important;
}

.smartphoto-nav a {
  border-radius: 5px;
  opacity: 0.6 !important;
  border: var(--border);
}

.smartphoto-list li {
  display: table !important;
}

//  !Gallery styles //

.ck.ck-button,
a.ck.ck-button {
  border: 1px solid var(--border);
}

.theme-switch {
  .demo-icon {
    margin: 0 auto !important;
  }

  position: relative;
  padding: 10px;
  border-radius: 50px;
  background-color: var(--primary-bg-color)-02;
  height: 50px;
  width: 50px;
  display: flex !important;
  align-items: center;
  text-align: center;
  justify-content: center;

  a {
    position: absolute;

    svg {
      fill: var(--primary-bg-color);
    }
  }
}

@media (max-width: 576px) {
  .error-page1 {
    .demo-icon {
      display: none;
    }
  }
  .wizard > .steps .current a .title,
  .wizard > .steps .current a:hover .title,
  .wizard > .steps .current a:active .title {
    display: none !important;
  }
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
  background-color: var(--primary-bg-color) !important;
}

.twentytwenty-container {
  border-radius: 5px !important;
}

.pcr-app[data-theme="classic"] {
  border-radius: 5px !important;
}

.pcr-app[data-theme="monolith"] {
  border-radius: 5px !important;
}

.pcr-app[data-theme="nano"] {
  border-radius: 5px !important;
}

.file-detailimg {
  img {
    width: 1000%;
    height: 100%;
  }
}

#gallery {
  img {
    width: 360px;
  }
}

.chart-circle {
  display: block;
  height: 6rem;
  width: 6rem;
  position: relative;
}

.chart-circle canvas {
  margin: 0 auto;
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.chart-circle .chart-circle-value.circle-style {
  position: absolute;
  top: 18px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
  line-height: 1;
  width: 60px;
  height: 60px;
  border: 2px dotted var(--primary-bg-color);
  border-radius: 50%;
  background: transparent;
}

.chart-circle-value {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
  line-height: 1;
}

.chart-circle[data-color] {
  color: var(--primary-bg-color);
}

@media screen and (max-width: 1024px) {
  .cardbody {
    padding-left: 0;
  }

  .item-card .product-grid6 .cardprice {
    top: 20px;
    right: 0;
  }
}

@media (orientation: landscape) and (max-width: 991px) {
  .smartphoto-img {
    width: 250px !important;
    left: 80%;
    display: block;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .smartphoto-img-wrap {
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 33%;
    right: 0;
    transform: none !important;
    justify-content: center;
    align-items: center;
  }
}
@media (orientation: landscape) and (min-width: 992px) and (max-width: 991px) {
  .smartphoto-img {
    width: 350px !important;
    left: 75%;
    display: block;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .smartphoto-img-wrap {
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20%;
    right: 0;
    transform: none !important;
    justify-content: center;
    align-items: center;
  }
}
.SumoSelect.disabled > .CaptionCont {
  background-color: #f9fbfb;
}
.SumoSelect.disabled {
  .SlectBox,
  .testselect2 {
    display: none;
  }
}
.index1 {
  .circle-icon {
    height: 35px;
    width: 35px;
    position: initial;
    border-radius: 50%;
    i {
      line-height: 2.2 !important;
    }
  }
}
.transaction-icon {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.featured_icon1.danger {
  border: 2px solid $danger;
}
.featured_icon1.success {
  border: 2px solid $success;
}
.featured_icon1.warning {
  border: 2px solid $warning;
}
.featured_icon1.teal {
  border: 2px solid $teal;
}
.featured_icon1.info {
  border: 2px solid $info;
}
.featured_icon1.secondary {
  border: 2px solid $secondary;
}
.featured_icon1 {
  width: 15px;
  height: 15px;
  line-height: 60px;
  margin-left: -34px !important;
  color: #000;
  font-size: 10px;
  border: 2px solid var(--primary-bg-color);
  position: relative;
  margin-top: -40px;
  border-radius: 18px;
  top: 20px;
}
.table-edit,
.table-delete {
  fill: var(--white);
}
.apexcharts-xaxistooltip.apexcharts-xaxistooltip-bottom.light.active {
  display: none !important;
}
.apexcharts-tooltip.light {
  border: 1px solid var(--primary-bg-color)-border !important;
  background: var(--primary-bg-color) !important;
  color: #fff !important;
}
.apexcharts-tooltip.light .apexcharts-tooltip-title {
  background: var(--primary-bg-color) !important;
  border-bottom: 1px solid var(--white)-3 !important;
}
.apexcharts-tooltip-marker {
  background-color: var(--white) !important;
}
.lh-maincard {
  line-height: 1.6 !important;
}
#country-selector .modal-body ul li {
  list-style-type: none;
}
.modal-fullscreen{
  max-width: initial;
  margin: auto;
}
@media screen and (max-width:991.8px) {
  .side-menu {
    margin-inline: 0 !important;
  }
}
.counter-side-badge {
  position: absolute;
  top: 0;
  right: 0;
  height: 100px;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
}
.counter-side-badge .badge {
  position: relative;
  left: -60px;
  top: 13px;
  width: 170px;
  font-weight: 400;
  font-size: 10px;
  margin: 0;
  text-align: center;
  padding: 5px 9px;
  float: left;
  transform: rotate(-45deg);
}

@media (max-width: 576px) {
  .main-mail-list .main-mail-star {
    display: none;
  }
  .theme-container button, .theme-container1 button, .theme-container2 button {
    font-size: 13px;
  }
}

.horizontal-content {
  .input-group .btn {
      z-index: 0;
  }
  @media (min-width: 768px) {
    .table-responsive.deleted-table .data-table-btn {
      z-index: 0;
    }
  }
  .page-item.active .page-link {
    z-index: 0;
  }
}

.note-editor {
  input[type=radio], input[type=checkbox] {
    margin-right: 7px;
  }
}
.main-cart-list .main-cart-item:hover {
 background-color: #fcfcff;
}


.btn-group.file-attach {
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-sm {
    font-size: 12px !important;
    line-height: 0.8;
  }
  .btn-close {
      padding: 9px;
      border-radius: 0 4px 4px 0;
      opacity: 1;
      font-weight: 300;
      background-image: none;
    &.rounded-pill {
      border-radius: 0 50px 50px 0 !important;
    }
    &.btn-outline-primary {
      border: 1px solid var(--primary-bg-color);
    }
    &.btn-outline-secondary {
      border: 1px solid $secondary;
    }
    &.btn-outline-info {
      border: 1px solid $info;
    }
    &.btn-outline-danger {
      border: 1px solid $danger;
    }
    &.btn-outline-warning {
      border: 1px solid $warning;
    }
    &.btn-outline-success {
      border: 1px solid $success;
    }
    
    &.btn-primary-light {
      border: 1px solid var(--primary-bg-color)-02;
      border-left: 0;
      color: var(--primary-bg-color);
      &:hover {
        color: var(--white);
      }
    }
    &.btn-secondary-light {
      border: 1px solid rgba($secondary, 0.2);
      border-left: 0;
      color: $secondary;
      &:hover {
        color: var(--white);
      }
    }
    &.btn-info-light {
      border: 1px solid rgba($info, 0.2);
      border-left: 0;
      color: $info;
      &:hover {
        color: var(--white);
      }
    }
    &.btn-success-light {
      border: 1px solid rgba($success, 0.2);
      border-left: 0;
      color: $success;
      &:hover {
        color: var(--white);
      }
    }
    &.btn-warning-light {
      border: 1px solid rgba($warning, 0.2);
      border-left: 0;
      color: $warning;
      &:hover {
        color: var(--white);
      }
    }
    &.btn-danger-light {
      border: 1px solid rgba($danger, 0.2);
      border-left: 0;
      color: $danger;
      &:hover {
        color: var(--white);
      }
    }
    &.btn-sm {
      font-size: 1rem !important;
      background-image: none;
    }
    &.btn-lg {
      padding: 10px !important;
      background-image: none;
      font-size: 30px !important;
    }
  }
}

@media (max-width: 450px) {
  .group-btn {
    .btn {
      font-size: 10px;
    }
  }
}
@media (max-width: 576px) {
  .card-pay .tabs-menu li {
    width: 100% !important;
  }
}

.horizontal {
  .input-group .form-control.is-valid {
    z-index: 0;
  }
}
@media (min-width: 1200px) {
  .main-nav-line-chat .nav-link+.nav-link {
    margin-left: 0 !important;
  }
}

@media (min-width: 576px) {
  .main-nav-line-chat .nav-link {
    margin-right: 5px;
  }
}

@media (min-width: 1500px) and (max-width: 1850px) {
  .layout-boxed {
    .upgrade-chart-circle {
      display: block !important;
      margin: auto;
    }
  }
}