.bg-transparent {
    background-color: transparent;
}

.bg-gray-100 {
    background-color: #f9f9fb;
}

.bg-gray-200 {
    background-color: #f0f0f8;
}

.bg-gray-300 {
    background-color: #e1e1ef;
}

.bg-gray-400 {
    background-color: #d6d6e6;
}

.bg-gray-500 {
    background-color: #949eb7;
}

.bg-gray-600 {
    background-color: #7987a1;
}

.bg-gray-700 {
    background-color: #4d5875;
}

.bg-gray-800 {
    background-color: #383853;
}

.bg-gray-900 {
    background-color: #323251;
}

.bg-white-1 {
    background-color: rgba(255, 255, 255, 0.1);
}

.bg-white-2 {
    background-color: rgba(255, 255, 255, 0.2);
}

.bg-white-3 {
    background-color: rgba(255, 255, 255, 0.3);
}

.bg-white-4 {
    background-color: rgba(255, 255, 255, 0.4);
}

.bg-white-5 {
    background-color: rgba(255, 255, 255, 0.5);
}

.bg-white-6 {
    background-color: rgba(255, 255, 255, 0.6);
}

.bg-white-7 {
    background-color: rgba(255, 255, 255, 0.7);
}

.bg-white-8 {
    background-color: rgba(255, 255, 255, 0.8);
}

.bg-white-9 {
    background-color: rgba(255, 255, 255, 0.9);
}

.bg-black-1 {
    background-color: rgba(0, 0, 0, 0.1);
}

.bg-black-2 {
    background-color: rgba(0, 0, 0, 0.2);
}

.bg-black-3 {
    background-color: rgba(0, 0, 0, 0.3);
}

.bg-black-4 {
    background-color: rgba(0, 0, 0, 0.4);
}

.bg-black-5 {
    background-color: rgba(0, 0, 0, 0.5);
}

.bg-black-6 {
    background-color: rgba(0, 0, 0, 0.6);
}

.bg-black-7 {
    background-color: rgba(0, 0, 0, 0.7);
}

.bg-black-8 {
    background-color: rgba(0, 0, 0, 0.8);
}

.bg-black-9 {
    background: rgba(0, 0, 0, 0.9);
}

.bg-indigo {
    background: #5b67c7;
}

.bg-purple {
    background: #ad4ffa;
}

.bg-primary {
    background-color: var(--primary-bg-color) !important;
}

a.bg-primary:hover,
a.bg-primary:focus {
    background-color: var(--primary-bg-color) !important;
}

.bg-transparent {
    background: transparent !important;
}

button.bg-primary:hover,
button.bg-primary:focus {
    background-color: var(--primary-bg-color) !important;
}

.bg-secondary {
    background-color: #f74f75 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus {
    background-color: #f74f75 !important;
}

button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: #f74f75 !important;
}

.bg-success {
    background-color: #24d5b8 !important;
}

a.bg-success:hover,
a.bg-success:focus {
    background-color: #05957d !important;
}

button.bg-success:hover,
button.bg-success:focus {
    background-color: #00927a !important;
}

.bg-info {
    background-color: #4ec2f0 !important;
}

a.bg-info:hover,
a.bg-info:focus {
    background-color: #4ec2f0 !important;
}

button.bg-info:hover,
button.bg-info:focus {
    background-color: #4ec2f0 !important;
}

.bg-warning {
    background-color: #ffbd5a !important;
}

a.bg-warning:hover,
a.bg-warning:focus {
    background-color: #f3a024 !important;
}

button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #f3a024 !important;
}

.bg-danger {
    background-color: #f34343 !important;
}

a.bg-danger:hover,
a.bg-danger:focus {
    background-color: #ee1f1f !important;
}

button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #ee1f1f !important;
}

.bg-purple {
    background-color: #ad4ffa !important;
}

a.bg-purple:hover,
a.bg-purple:focus {
    background-color: #5930a2 !important;
}

button.bg-purple:hover,
button.bg-purple:focus {
    background-color: #5930a2 !important;
}

.bg-light {
    background-color: #f9f9fb !important;
}

a.bg-light:hover,
a.bg-light:focus {
    background-color: #d5d9e4 !important;
}

button.bg-light:hover,
button.bg-light:focus {
    background-color: #d5d9e4 !important;
}

.bg-dark {
    background-color: #383853 !important;
}

a.bg-dark:hover,
a.bg-dark:focus {
    background-color: #283143 !important;
}

button.bg-dark:hover,
button.bg-dark:focus {
    background-color: #283143 !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.bg-gray+.select2-container--default .select2-selection--single {
    background-color: #455473;
}

.bg-gray+.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #e1e1ef;
}

.bg-pink {
    background: #f754fa !important;
}

.bg-orange {
    background: #fd7e14;
}

.bg-teal {
    background: #29ddee !important;
}

.bg-purple-dark {
    background: #59339d;
}

.bg-transparent {
    background: transparent;
}

.bg-success-transparent {
    background-color: rgba(26, 156, 134, 0.2) !important;
}

.bg-danger-transparent {
    background-color: rgba(243, 67, 67, 0.2) !important;
}

.bg-primary-transparent {
    background: var(--primary02) !important;
}

.bg-secondary-transparent {
    background-color: rgba(247, 79, 117, 0.2) !important;
}

.bg-white-transparent {
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.bg-warning-transparent {
    background-color: rgba(255, 189, 90, 0.2) !important;
}

.bg-pink-transparent {
    background-color: rgba(247, 84, 250, 0.2) !important;
}

.bg-purple-transparent {
    background-color: rgba(173, 79, 250, 0.13) !important;
}

.bg-teal-transparent {
    background-color: rgba(25, 192, 192, 0.2) !important;
}

.bg-info-transparent {
    background-color: rgba(78, 194, 240, 0.2) !important;
}

.bg-orange-transparent {
    background-color: rgba(253, 126, 20, 0.2);
}

.bg-gradient-blue {
    background-image: linear-gradient(to right, #1976d2 0, #64b5f6 100%);
}

.bg-primary {
    background-color: var(--primary-bg-color) !important;
}

.bg-primary-gradient {
    background-image: linear-gradient(to right, var(--primary-bg-color) 0%, var(--primary05) 100%) !important;
}

.bg-danger-gradient {
    background-image: linear-gradient(to right, #f34343 0%, rgba(243, 67, 67, 0.5) 100%) !important;
}

.bg-success-gradient {
    background-image: linear-gradient(to right, #24d5b8 0%, rgba(36, 213, 184, 0.5) 100%) !important;
}

.bg-warning-gradient {
    background-image: linear-gradient(to right, #ffbd5a 0%, rgba(255, 189, 90, 0.5) 100%) !important;
}

.bg-primary-gradient1 {
    background-image: linear-gradient(to top, #64c5eb 0%, var(--primary-bg-color) 100%) !important;
}

.bg-info-gradient1 {
    background-image: linear-gradient(to top, #4ec2f0 0%, #31eccd 100%) !important;
}

.bg-purple-gradient {
    background-image: linear-gradient(to right, #673AB7 0%, #ba7bfb 100%) !important;
}

.bg-info-gradient {
    background-image: linear-gradient(to right, #4ec2f0 0%, rgba(78, 194, 240, 0.5) 100%) !important;
}

.bg-secondary-gradient {
    background-image: linear-gradient(to right, #f74f75 0%, rgba(247, 79, 117, 0.5) 100%) !important;
}

.bg-pink-gradient {
    background-image: linear-gradient(to right, #f754fa 0%, #f36eae 100%) !important;
}

.bg-purple-gradient {
    background-image: linear-gradient(to right, #ad4ffa 0%, #884af1 100%) !important;
}

.bg-teal-gradient {
    background-image: linear-gradient(to right, #29ddee 0%, #96e8e8 100%) !important;
}

.bg-azure {
    background-color: #45aaf2 !important;
}

.bg-red {
    background-color: #dc0441 !important;
    color: #fff !important;
}

.bg-yellow {
    background-color: #ecb403 !important;
    color: #fff !important;
}

.bg-lime {
    background-color: #1e74a0 !important;
}

.bg-green {
    background-color: #2dce89 !important;
    color: #fff !important;
}

.tag-outline-primary {
    color: var(--primary-bg-color) !important;
    border-color: var(--primary-bg-color) !important;
}

.tag-outline-secondary {
    color: #f74f75 !important;
    border-color: #f74f75 !important;
}

.tag-outline-success {
    color: #24d5b8 !important;
    border-color: #24d5b8 !important;
}

.tag-outline-warning {
    color: #ffbd5a !important;
    border-color: #ffbd5a !important;
}

.tag-outline-info {
    color: #4ec2f0 !important;
    border-color: #4ec2f0 !important;
}

.tag-outline-danger {
    color: #f34343 !important;
    border-color: #f34343 !important;
}

.bg-chartcustom {
    background-color: #ebeff5;
}