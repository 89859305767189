
.main-footer {
  background-color: #FFFFFFFF;
  border-top: 1px solid #EDEDF5FF;
  margin-top: auto;
}

.main-footer-app .container,
.main-footer-app .container-fluid {
  border-top-width: 0;
}


.footer .container {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 992px) {
  .main-footer {
    display: block;
  }

  .sticky {
    width: 100%;
  }
}

.main-footer {
  .container {
    font-size: 14px;
    padding: 28px 20px 0;
    color: #7987A1FF;
  }

  .container-fluid {
    font-size: 14px;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.main-footer-app {
  .container, .container-fluid {
    border-top-width: 0;
  }
}

@media (max-width: 480px) {
  .main-footer .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 380px) {
  .main-footer .container-fluid {
    font-size: 12px;
  }
}


@media (min-width: 992px) {
  .main-footer .container {
    padding: 10px 0 0;
  }
}


