
.table {
  color: var(--gray-dark);

  thead {

    th,
    td {
      font-weight: 600;
      font-size: 14px;
      letter-spacing: .5px;
      text-transform: capitalize;
      border-bottom-width: 1px;
      border-top-width: 0;
      padding: 10px 15px 10px;
    }
  }

  tbody tr {
    background-color: var(--white5);

    th {
      font-weight: 500;
    }
  }

  th,
  td {
    padding: 9px 15px;
    line-height: 1.462;
    font-size: 13px;
  }
}

.table-striped tbody tr:nth-of-type(4n+2) {
  background-color: rgba(238, 238, 247, 0.5);
}

.table-bordered thead {

  th, td {
    border-top-width: 1px;
    padding-top: 11px;
    padding-bottom: 11px;
    background-color: var(--white);
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: var(--default-color);

  th,
  td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid var(--gray-200);
  }

  thead th {
    vertical-align: bottom;
  }

  tbody+tbody {
    border-top: 2px solid transparent !important;
  }
}

.table-sm {
  th, td {
    padding: 0.3rem;
  }
}

.table-bordered {
  border: 1px solid #e1e1ef;

  th, td {
    border: 1px solid #e1e1ef;
  }

}

.table-borderless {

  th, td,
  thead th,
  tbody+tbody {
    border: 0;
  }
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: var(--gray100);
}

.table-hover tbody tr:hover {
  color: var(--default-color)-color;
  background-color: var(--bg-hover);
}

.table-primary {
  background-color: #c6d4ff;

  > {

    th,
    td {
      background-color: #c6d4ff;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #95afff;
  }
}

.table-hover .table-primary:hover {
  background-color: #adc1ff;

  > {

    td,
    th {
      background-color: #adc1ff;
    }
  }
}

.table-secondary {
  background-color: #d9dde5;

  > {

    th,
    td {
      background-color: #d9dde5;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #b9c1ce;
  }
}

.table-hover .table-secondary:hover {
  background-color: #cacfdb;

  > {

    td,
    th {
      background-color: #cacfdb;
    }
  }
}

.table-success {
  background-color: #c8e9b8;

  > {

    th,
    td {
      background-color: #c8e9b8;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #99d67b;
  }
}

.table-hover .table-success:hover {
  background-color: #b9e3a5;

  > {

    td,
    th {
      background-color: #b9e3a5;
    }
  }
}

.table-info {
  background-color: #bee5eb;

  > {

    th,
    td {
      background-color: #bee5eb;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #86cfda;
  }
}

.table-hover .table-info:hover {
  background-color: #abdde5;

  > {

    td,
    th {
      background-color: #abdde5;
    }
  }
}

.table-light {
  background-color: #fcfcfd;

  > {

    th,
    td {
      background-color: #fcfcfd;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #f9fafb;
  }
}

.table-hover .table-light:hover {
  background-color: #ededf3;

  > {

    td,
    th {
      background-color: #ededf3;
    }
  }
}

.table-dark {
  background-color: #c8ccd3;

  > {

    th,
    td {
      background-color: #c8ccd3;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #99a0ae;
  }
}

.table-hover .table-dark:hover {
  background-color: #babfc8;

  > {

    td,
    th {
      background-color: #babfc8;
    }
  }
}

.table-active {
  background-color: rgba(0, 0, 0, 0.075);

  > {

    th,
    td {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);

  > {

    td,
    th {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }
}

.table {
  .thead-dark th {
    color: var(--white);
    background-color: var(--gray-dark);
    border-color: #49597b;
  }

  .thead-light th {
    color: var(--gray-700);
    background-color: var(--gray-200);
    border-color: var(--gray-300);
  }
}

.table-dark {
  color: var(--white);
  background-color: var(--gray-dark);

  th,
  td,
  thead th {
    border-color: #49597b;
  }

  &.table-bordered {
    border: 0;
  }

  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: var(--white05);
  }

  &.table-hover tbody tr:hover {
    color: var(--white);
    background-color: var(--white75);
  }
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    >.table-bordered {
      border: 0;
    }
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media print {
  .table {
    border-collapse: collapse !important;

    td,
    th {
      background-color: var(--white) !important;
    }
  }

  .table-bordered {
    th,
    td {
      border: 1px solid #e1e1ef !important;
    }
  }

  .table-dark {
    color: inherit;

    th,
    td,
    thead th,
    tbody+tbody {
      border-color: var(--gray-300);
    }
  }

  .table .thead-dark th {
    color: inherit;
    border-color: var(--gray-300);
  }
}

.table.table-clean {
  td {
    .value {
      font-size: .9rem;
      line-height: 1.6;
      font-weight: 500;
    }

    .sub-value {
      font-size: .72rem;
      color: var(--gray-600);
    }
  }

  tr:first-child td {
    border-top: none;
  }

  td {
    padding-left: 0;
    padding-right: 0;
    border-top-color: rgba(0, 0, 0, 0.05);
  }

  th,
  td {
    padding: 7px 20px !important;
  }
}

@media (max-width: 1199px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.table>:not(:last-child)>:last-child>* {
  border-bottom-color: #ededf5;
}

.table-bordered>:not(caption)>* {
  border-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  --bs-table-accent-bg: rgb(255, 255, 255);
  color: var(--dark);
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: var(--border);
  border-style: none;
  border-width: 0;
}

.table-hover>tbody>tr:hover {
  --bs-table-accent-bg: none !important;
  background: var(--bg-hover);
}


@media (min-width: 768px) {
  .table-responsive.deleted-table .data-table-btn {
    position: absolute;
    left: 185px;
    z-index: 999;
  }
}

@media (min-width: 1319px) {
  .table-responsive.export-table .dt-buttons.btn-group {
    position: absolute;
    top: 0;
    left: 172px;
  }
}

.table> :not(:first-child) {
  border-top: 1px solid var(--border) !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: var(--white) !important;
}


table.dataTable.dtr-inline.collapsed>tbody>tr>td.child,
table.dataTable.dtr-inline.collapsed>tbody>tr>th.child {
  cursor: default !important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dataTables_empty {
  cursor: default !important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.child:before {
  display: none !important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>th.child:before {
  display: none !important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dataTables_empty:before {
  display: none !important;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
  top: 50%;
  left: 5px;
  height: 1em;
  width: 1em;
  margin-top: -9px;
  display: block;
  position: absolute;
  color: white;
  border: 0.15em solid white;
  border-radius: 1em;
  box-shadow: 0 0 0.2em #777086;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: "Courier New", Courier, monospace;
  line-height: 1em;
  content: "+";
  background-color: #6c5ffc;
}

table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th.dtr-control:before {
  content: "-";
  background-color: #f82649;
}

table.dataTable.dtr-inline.collapsed.compact>tbody>tr>td.dtr-control,
table.dataTable.dtr-inline.collapsed.compact>tbody>tr>th.dtr-control {
  padding-left: 27px;
}

table.dataTable.dtr-inline.collapsed.compact>tbody>tr>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed.compact>tbody>tr>th.dtr-control:before {
  left: 4px;
  height: 14px;
  width: 14px;
  border-radius: 14px;
  line-height: 14px;
  text-indent: 3px;
}

table.dataTable.dtr-column>tbody>tr>td.dtr-control,
table.dataTable.dtr-column>tbody>tr>th.dtr-control,
table.dataTable.dtr-column>tbody>tr>td.control,
table.dataTable.dtr-column>tbody>tr>th.control {
  position: relative;
  cursor: pointer;
}

table.dataTable.dtr-column>tbody>tr>td.dtr-control:before,
table.dataTable.dtr-column>tbody>tr>th.dtr-control:before,
table.dataTable.dtr-column>tbody>tr>td.control:before,
table.dataTable.dtr-column>tbody>tr>th.control:before {
  top: 50%;
  left: 50%;
  height: 0.8em;
  width: 0.8em;
  margin-top: -0.5em;
  margin-left: -0.5em;
  display: block;
  position: absolute;
  color: white;
  border: 0.15em solid white;
  border-radius: 1em;
  box-shadow: 0 0 0.2em #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: "Courier New", Courier, monospace;
  line-height: 1em;
  content: "+";
  background-color: #0d6efd;
}

table.dataTable.dtr-column>tbody>tr.parent td.dtr-control:before,
table.dataTable.dtr-column>tbody>tr.parent th.dtr-control:before,
table.dataTable.dtr-column>tbody>tr.parent td.control:before,
table.dataTable.dtr-column>tbody>tr.parent th.control:before {
  content: "-";
  background-color: #d33333;
}

table.dataTable>tbody>tr.child {
  padding: 0.5em 1em;
}

table.dataTable>tbody>tr.child:hover {
  background: transparent !important;
}

table.dataTable>tbody>tr.child ul.dtr-details {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

table.dataTable>tbody>tr.child ul.dtr-details>li {
  border-bottom: 1px solid #efefef;
  padding: 0.5em 0;
}

table.dataTable>tbody>tr.child ul.dtr-details>li:first-child {
  padding-top: 0;
}

table.dataTable>tbody>tr.child ul.dtr-details>li:last-child {
  border-bottom: none;
}

table.dataTable>tbody>tr.child span.dtr-title {
  display: inline-block;
  min-width: 75px;
  font-weight: bold;
}

@media (max-width: 583px) {
  .dt-buttons {
    .btn,
    .sp-container button {
      padding: 0.5rem 0.1rem;
    }
  }
}

table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable td,
table.dataTable th {
  box-sizing: content-box;
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  text-align: center;
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length label {
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
}

div.dataTables_wrapper div.dataTables_length select {
  width: auto;
  display: inline-block;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}

div.dataTables_wrapper div.dataTables_filter label {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0.5em;
  display: inline-block;
  width: auto;
}

div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.85em;
}

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  margin-left: -100px;
  margin-top: -26px;
  text-align: center;
  padding: 1em 0;
}

table.dataTable>thead>tr>th:active,
table.dataTable>thead>tr>td:active {
  outline: none;
}

table.dataTable>thead>tr>th:not(.sorting_disabled),
table.dataTable>thead>tr>td:not(.sorting_disabled) {
  padding-right: 30px;
}

table.dataTable>thead .sorting,
table.dataTable>thead .sorting_asc,
table.dataTable>thead .sorting_desc,
table.dataTable>thead .sorting_asc_disabled,
table.dataTable>thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}

table.dataTable>thead .sorting:before,
table.dataTable>thead .sorting:after {
  display: block;
  opacity: 0.3;
  font-family: "Ionicons"; /* Add the font-family here */
  font-size: 11px;
}

table.dataTable>thead .sorting_asc:before,
table.dataTable>thead .sorting_asc:after {
  display: block;
  opacity: 1;
  font-family: "Ionicons"; /* Add the font-family here */
  font-size: 11px;
}

table.dataTable>thead .sorting_desc:before,
table.dataTable>thead .sorting_desc:after {
  display: block;
  opacity: 1;
  font-family: "Ionicons"; /* Add the font-family here */
  font-size: 11px;
}

table.dataTable>thead .sorting_asc_disabled:before,
table.dataTable>thead .sorting_asc_disabled:after {
  position: absolute;
  bottom: 0.5em;
  display: block;
  opacity: 0.3;
  font-family: "Ionicons"; /* Add the font-family here */
  font-size: 11px;
}

table.dataTable>thead .sorting_desc_disabled:before,
table.dataTable>thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.5em;
  display: block;
  opacity: 0.3;
  font-family: "Ionicons"; /* Add the font-family here */
  font-size: 11px;
}

table.dataTable>thead .sorting:before,
table.dataTable>thead .sorting_asc:before,
table.dataTable>thead .sorting_desc:before,
table.dataTable>thead .sorting_asc_disabled:before,
table.dataTable>thead .sorting_desc_disabled:before {
  right: 1em;
  content: "\f3d8";
}

table.dataTable>thead .sorting:after,
table.dataTable>thead .sorting_asc:after,
table.dataTable>thead .sorting_desc:after,
table.dataTable>thead .sorting_asc_disabled:after,
table.dataTable>thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\f3d0";
}

table.dataTable>thead .sorting_asc:before,
table.dataTable>thead .sorting_desc:after {
  opacity: 1;
}

table.dataTable>thead .sorting_asc_disabled:before,
table.dataTable>thead .sorting_desc_disabled:after {
  opacity: 0;
}

div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

div.dataTables_scrollBody table thead .sorting:before,
div.dataTables_scrollBody table thead .sorting_asc:before,
div.dataTables_scrollBody table thead .sorting_desc:before,
div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none;
}

div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none;
}

div.dataTables_scrollFoot>.dataTables_scrollFootInner {
  box-sizing: content-box;
}

div.dataTables_scrollFoot>.dataTables_scrollFootInner>table {
  margin-top: 0 !important;
  border-top: none;
}

@media screen and (max-width: 767px) {

  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info {
    text-align: center;
  }

  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }

  div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    justify-content: center !important;
  }
}

table.dataTable.table-sm>thead>tr>th:not(.sorting_disabled) {
  padding-right: 20px;
}

table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
  top: 5px;
  right: 0.85em;
}

table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
  top: 5px;
}

table.table-bordered.dataTable {
  border-right-width: 0;
}

table.table-bordered.dataTable thead tr:first-child th,
table.table-bordered.dataTable thead tr:first-child td {
  border-top-width: 0;
}

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0;
}

table.table-bordered.dataTable th:first-child,
table.table-bordered.dataTable td:first-child {
  border-left-width: 0;
}

table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child {
  border-right-width: 1px;
}

table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-bottom-width: 0;
}

div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}

div.table-responsive>div.dataTables_wrapper>div.row {
  margin: 0;
}

div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:first-child {
  padding-left: 0;
}

div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:last-child {
  padding-right: 0;
}


.dataTables_wrapper .selected {
  background: #f6f6fb;
  cursor: pointer;
}

#delete-datatable tr {
  cursor: pointer;
}

table.dataTable tfoot th,
table.dataTable tfoot td {
  padding: 10px 18px 6px 18px;
  border-top: 1px solid #f0f0f8;
}

.table-responsive.hoverable-table {
  position: relative;
}

.hoverable-table .btn-primary {
  position: absolute;
  margin-left: 98px;
  top: 0;
  padding: 7px 16px;
  z-index: 99;
}

#basic-datatable .select2.select2-container.select2-container--default.select2-container--below.select2-container--focus,
#responsive-datatable .select2.select2-container.select2-container--default.select2-container--below.select2-container--focus,
#delete-datatable .select2.select2-container.select2-container--default.select2-container--below.select2-container--focus,
#file-datatable .select2.select2-container.select2-container--default.select2-container--below.select2-container--focus {
  width: 100% !important;
}

#example1 .select2.select2-container.select2-container--default.select2-container--below.select2-container--focus {
  width: auto !important;
}

table.dataTable thead .sorting_desc::after {
  content: "\f3d0"; /* Use the same descending icon as before */
}

table.dataTable thead .sorting {
  background-image: none;
  position: relative;
}

table.dataTable thead .sorting::before,
table.dataTable thead .sorting::after {
  font-family: "Ionicons";
  font-size: 11px;
  position: absolute;
  line-height: 0;
  right: 10px;
  transform-origin: center;
}

table.dataTable thead .sorting::before {
  content: "\f3d8"; /* Use the ascending icon instead of \f3d8 */
  top: 40%;
}

table.dataTable thead .sorting::after {
  content: "\f3d0"; /* Use the same descending icon as before */
  top: 49%;
}


table.dataTable.dtr-inline.collapsed>tbody>tr[role=row]>td:first-child::before {
  top: 9.5px;
  left: 7px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 0;
  box-shadow: none;
  background-color: #e1e1ef;
  font-size: 14px;
  font-weight: 700;
  color: #949eb7;
}

table.dataTable>tbody>tr.child ul.dtr-details {
  display: block;
}

table.dataTable>tbody>tr.child span.dtr-title {
  font-weight: 500;
}

.dataTables_wrapper .dataTables_length {
  text-align: left;
}

.dataTables_wrapper .dataTables_length label {
  display: block;
  margin-bottom: 15px;
}

.dataTables_wrapper .dataTables_length .select2-container--default {
  margin-right: 5px;
  width: 50px !important;
}

.dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single {
  height: 32px;
  border-color: #cdd4e2;
  border-radius: 3px;
}

.dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 32px;
}

.dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 30px;
}

.dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-top-color: #949eb7;
}

.dataTables_wrapper .dataTables_filter {
  text-align: left;
}

.dataTables_wrapper .dataTables_filter label {
  display: block;
  margin-bottom: 15px;
}

.dataTables_wrapper .dataTables_filter input {
  margin-left: 0;
  border: 1px solid #ededf5;
  padding: 16px 10px;
  line-height: 1.539;
  color: #4a4a69;
  border-radius: 3px;
  width: 100%;
}

.dataTables_wrapper .dataTables_filter input::placeholder {
  color: #949eb7;
}

.dataTables_wrapper .dataTables_info {
  margin-top: 19px !important;
  padding: 0 !important;
  text-align: left;
}

.dataTables_wrapper .dataTables_paginate {
  margin-top: 15px !important;
  padding-top: 0;
  text-align: left;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 1px;
  transition: all 0.2s ease-in-out;
}

.dataTables_wrapper .dataTables_paginate .paginate_button+.paginate_button {
  margin-left: 0;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
  background-color: transparent;
  color: #e1e1ef !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:focus {
  color: #e1e1ef !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous {
  margin-right: 3px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.next {
  margin-left: 3px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:focus {
  border: 0;
  background-image: none;
  background-color: transparent;
  color: #4a4a69 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  border: 0;
  background-image: none;
  background-color: var(--primary-bg-color);
  color: #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:focus {
  border: 0;
  background-image: none;
  background-color: var(--primary-bg-color);
  color: #fff !important;
}

@media (min-width: 576px) {
  .dataTables_wrapper .dataTables_filter {
    float: right;
    margin-top: 0;
  }
}

@media (min-width: 576px) {
  .dataTables_wrapper .dataTables_filter input {
    width: auto;
  }
}

@media (min-width: 576px) {
  .dataTables_wrapper .dataTables_info {
    float: left;
  }
}

@media (min-width: 576px) {
  .dataTables_wrapper .dataTables_paginate {
    float: right;
  }
}

@media (prefers-reduced-motion: reduce) {
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    transition: none;
  }
}

@media (max-width: 414px) {
  div.dataTables_wrapper div.dataTables_filter input {
    margin-left: -12px;
  }
}

.table.dataTable thead .sorting:before {
  display: none;
}

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  display: none;
}

.dataTables_paginate .pagination .page-link {
  padding: 0.5rem 0.75rem;
  background-color: #f9f9fb;
  border: 1px solid #ededf5;
}

.dataTables_paginate .page-item.active .page-link {
  background-color: var(--primary-bg-color);
}

.dataTables_wrapper .dataTables_paginate .paginate_button.focus {
  background: var(--primary-bg-color);
}

.dataTables_wrapper .dataTables_paginate .page-item.disabled .page-link {
  background-color: #ffffff;
  width: 65px;
  color: #7a839b;
  FONT-WEIGHT: 100;
  font-size: 12px;
}

.page-item.next a {
  width: 64px;
}

table.dataTable {
  border: 1px solid #ededf5;
}

table.dataTable thead th,
table.dataTable thead td {
  border-top-width: 0;
  border-bottom-width: 0;
  padding: 15px 10px;
  font-weight: 500;
  font-size: 12px;
  color: #323251;
}

table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
  background-image: none;
  background-color: #fff;
  position: relative;
}

table.dataTable thead .sorting_asc::after,
table.dataTable thead .sorting_desc::after {
  font-family: "Ionicons";
  font-size: 11px;
  position: absolute;
  line-height: 0;
  top: 50%;
  right: 10px;
}

table.dataTable thead .sorting_asc::after {
  transform: rotate(180deg);
}
