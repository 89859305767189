.dark-theme :where(.css-dev-only-do-not-override-acm2ia).ant-select:not(:where(.css-dev-only-do-not-override-acm2ia).ant-select-customize-input) .ant-select-selector {
  background-color: #2a2e3f;
  &:focus {
    outline: var(--primary-bg-color);
  }
}

.dark-theme :where(.css-dev-only-do-not-override-acm2ia).ant-select-single .ant-select-selector {
  color: #c0c2c7;
  opacity: 1;
}

.dark-theme :where(.css-dev-only-do-not-override-acm2ia).ant-select .ant-select-arrow {
  color: #c0c2c7;

}

.dark-theme :where(.css-acm2ia).ant-select:not(:where(.css-acm2ia).ant-select-customize-input) .ant-select-selector {
  background-color: #2a2e3f;
  &:focus {
    outline: var(--primary-bg-color);
  }
}

.dark-theme :where(.css-acm2ia).ant-select-single .ant-select-selector {
  color: #c0c2c7;
  opacity: 1;
}

.dark-theme :where(.css-acm2ia).ant-select .ant-select-arrow {
  color: #c0c2c7;
}
