@import "sidemenu";

.nav-link.with-sub {
  .angle {
    -webkit-transform-origin: center;
    transform-origin: center;
    position: absolute;
    top: 17px;
    right: 23px;
    font-size: 12px;
  }
}

.sidebar-right .main-nav-line .nav-link {
  padding: 10px 18px 10px 21px;
  background: var(--gray-200);

  &.active::before {
    bottom: 0;
  }
}

.sidebar {
  .tab-menu-heading {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    margin-bottom: 27px;
    background: var(--gray-100);
  }

  .tabs-menu-body {
    margin-top: 56px;
  }

  .tabs-menu ul li {
    .active {
      background: var(--primary-bg-color);
      color: var(--white);
      border: 1px solid var(--primary-bg-color);
    }

    a {
      padding: 8px 8px;
      font-size: 12px;
      display: inline-block;
      border-radius: 3px;
      border: 1px solid #ebeaf1;
      font-weight: 500;
      background: transparent;
      color: var(--default-color);
      margin: 0 2px;
    }

    &:last-child a {
      margin-bottom: 0;
    }
  }

  .nav.panel-tabs {
    padding: 15px;
  }

  .avatar-md {
    width: 40px !important;
    height: 40px !important;
    font-size: 15px !important;
  }

  .avatar-lg {
    width: 50px !important;
    height: 50px !important;
  }

  position: fixed;
  display: block;
  top: 0;
  width: 320px;
  bottom: 0;
  z-index: 9999;
  height: 100%;
  background: var(--white);
  box-shadow: 0 18px 36px 10px rgba(154, 154, 204, 0.1);
  border-left: 1px solid var(--border);

  .card {
    box-shadow: none;
    border: 0 !important;
  }

  .list-group-item {
    margin-bottom: -2px;
  }
}


.sidebar.sidebar-left {
  -ms-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
}

.sidebar.sidebar-left.sidebar-open {
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.sidebar-animate {
  -webkit-transition: -webkit-transform 300ms ease;
  -moz-transition: -moz-transform 300ms ease;
  -o-transition: transform 300ms ease;
  transition: transform 300ms ease;
}

.sidebar {
  box-shadow: none !important;
}

.tabs-menu ul li a {
  padding: 10px 12px 11px 13px;
  display: block;
}
.sidebar .nav.panel-tabs .side-menu__icon {
  margin-right: 2px;
  width: 16px;
  height: 16px;
}
.sidebar {
  .tab-pane b {
    font-weight: 500;
  }
}

.sidebar {
  position: fixed;
  display: block;
  top: 0;
  width: 320px;
  bottom: 0;
  z-index: 9999;
}

.sidebar-left {
  left: 0;
}

.row .sidebar.sidebar-left.sidebar-xs-show {
  left: 0;
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

[data-bs-toggle=sidebar] {
  display: block;
}


.sub-slide2.is-expanded .sub-slide-menu1 {
  display: none;
}

.slide-menu .sub-slide-menu1 {
  padding: 0 10px;
}

.sub-slide-menu1 {
  display: none;
  font-size: 0.8rem !important;
  padding-left: 0;
  list-style: none;
}

.app-sidebar .slide-menu .slide-item:before {
  content: "\f22d";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 9px;
  left: 20px;
  font-size: 10px;
  color: #7b8191;
  font-weight: 900;
  opacity: 0.5;
}

.app-sidebar__toggle .close-toggle,
.sidebar-mini.sidenav-toggled .open-toggle {
  display: none;
}

.header-icon {
  text-align: center;
  line-height: 40px;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  text-align: center;
  line-height: 1.8 !important;
  font-size: 1.3rem !important;
  color: #7b8191;
  border: 0px solid #eae3f3;
  padding: 20px;
}

.sidebar-mini.sidenav-toggled .close-toggle {
  display: block;
  color: #000;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
}

.sidenav-toggled .app-content {
  transition: margin-left 320ms ease;
}

.sub-slide-menu .active .sub-slide-item.active {
  color: #f34343;
}

.app-sidebar .slide-menu a.active:before {
  color: var(--primary-bg-color);
}

.main-sidebar-header {
  height: 64px;
  padding: 12px 20px;
  width: 240px;
  background: #fff;
  border-bottom: 1px solid #ededf5;
  position: fixed;
  border-right: 1px solid #ededf5;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  -webkit-transition: left 320ms ease, width 320ms ease;
  -o-transition: left 320ms ease, width 320ms ease;
  transition: left 320ms ease, width 320ms ease;
  outline: none;
}

.side-header .header-brand1 {
  text-align: center;
  display: table;
}

.side-header .header-brand-img.desktop-logo {
  max-height: 2.5rem;
  text-align: center;
  display: block;
  margin-right: 0;
}

.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo,
.side-header .header-brand-img.toggle-logo {
  display: none;
}

.sidenav-toggled .app-sidebar .side-header .header-brand-img.toggle-logo {
  display: none;
  margin-right: 0;
}

.sidenav-toggled .app-sidebar__toggle {
  display: block;
}

.side-menu h3 {
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 1px;
  opacity: 1;
  padding: 20px 34px 10px;
  text-transform: capitalize;
  width: 100%;
  position: relative;
  color: #90909e;
  position: relative;
}

.side-menu h3:after {
  content: "--";
  position: absolute;
  left: 21px;
  top: 20px;
}

.sidenav-toggled .side-menu h3 {
  display: none;
}

.slide.is-expanded .slide-menu,
.sub-slide {
  display: block;
}

/*-- Sub-slide--**/
.sub-side-menu__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 7px 0;
  font-size: 12px;
}

.sub-side-menu__label {
  white-space: normal;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-weight: 400;
}

.sub-slide .sub-angle {
  transform-origin: center;
}

.sub-slide.is-expanded .sub-angle {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.sub-slide2 .sub-angle {
  transform-origin: center;
}

.sub-slide2.is-expanded .sub-angle2 {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.sub-slide-menu {
  list-style: none;
  padding: 0;
}

.sub-slide-item {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  list-style: none;
  padding: 8px 0 8px 25px !important;
}

.sub-slide-menu {
  display: none;
  font-size: 0.8rem !important;
  padding-left: 0;
  list-style: none;
}

.sub-slide.is-expanded .sub-slide-menu {
  display: block;
}

.slide-menu .sub-slide.is-expanded {
  display: block;
}

.sub-side-menu__item {
  padding-left: 40px !important;
  padding-right: 20px !important;
}

.sub-side-menu__item:hover {
  color: var(--primary-bg-color) !important;
}

.sub-slide.is-expanded .sub-slide-item {
  padding-left: 42px !important;
}

.sub-slide-menu1 .sub-slide-item2 {
  padding: 6px 0px 6px 48px !important;
  display: block;
  font-size: 11px;
}

.sub-slide .sub-angle2 {
  margin-left: 68px;
}

.sub-slide-menu1 .sub-slide-item2:hover {
  color: var(--primary-bg-color) !important;
}

.app-sidebar .side-menu__item.active:hover {
  color: #7282a9 !important;
}

.app-sidebar .slide-menu .sub-slide-menu a:before {
  left: 25px;
}

.app.sidenav-toggled .side-menu .side-menu__icon {
  margin-right: 0;
  margin-left: 0;
}

.slide-item .icon {
  margin-right: 5px;
}

.app .side-menu_label {
  display: none;
}

.app-sidebar__toggle:hover {
  color: #fff;
}

.app-sidebar__toggle:focus,
.app-sidebar__toggle:hover {
  text-decoration: none;
}

.main-sidemenu .side-menu {
  -webkit-transition: left 320ms ease, width 320ms ease;
  -o-transition: left 320ms ease, width 320ms ease;
  transition: left 320ms ease, width 320ms ease;
}

.sidebar-mini .app-sidebar {
  width: 240px !important;
}

.app-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  color: #14112d;
  max-height: 100%;
  z-index: 1024;
  background: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 8px 14.72px 1.28px rgba(154, 154, 204, 0.1);
  box-shadow: 0px 8px 14.72px 1.28px rgba(154, 154, 204, 0.1);
  border-right: 1px solid #ededf5;
  -webkit-transition: left 320ms ease, width 320ms ease;
  -o-transition: left 320ms ease, width 320ms ease;
  transition: left 320ms ease, width 320ms ease;
}

.app-sidebar::-webkit-scrollbar {
  width: 6px;
}

.app-sidebar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

.side-menu {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

.side-menu .slide .side-menu__item {
  padding: 8px 91px 10px 30px;
  margin: 0;
  border-radius: 0;
}

.slide {
  margin: 0;
}

.side-menu .slide .side-menu__item.active {
  background-color: #fff;
  border-right: 3px solid var(--primary-bg-color);
}

.side-menu .slide .side-menu__item.active .side-menu__icon {
  color: var(--primary-bg-color) !important;
}

.side-menu .slide .side-menu__item.active .side-menu__label {
  color: #4a4a69 !important;
  font-weight: 600;
}

.slide:hover .side-menu__label,
.slide:hover .angle,
.slide:hover .side-menu__icon {
  color: #7b8191;
  fill: #7b8191;
}

.slide-item.active,
.slide-item:hover,
.slide-item:focus {
  text-decoration: none;
  color: #b5c1d2;
}

.slide-item.active,
.slide-item:hover,
.slide-item:focus {
  text-decoration: none;
  color: var(--primary-bg-color) !important;
}

.slide-item .app-sidebar .slide-menu .slide-item:before:hover {
  color: var(--primary-bg-color) !important;
}

.slide.is-expanded .slide-menu a:hover:before {
  color: var(--primary-bg-color) !important;
}

.slide-menu .sub-slide-menu {
  padding: 0 10px;
}

.sub-slide .sub-angle {
  margin-left: auto;
  float: right;
  font-size: 12px;
  margin-top: 3px;
}

.slide-menu li {
  position: relative;
}

.slide.is-expanded a {
  color: #7b8191;
  text-decoration: none;
}

.slide.is-expanded .sub-side-menu__item:before {
  content: "\f22d";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 10px;
  left: 15px;
  font-size: 9px;
  color: #7b8191;
  font-weight: 900;
}

.sub-slide-menu1 .sub-slide-item2:before {
  content: "\f22d";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  left: 35px !important;
  font-size: 9px;
  color: #7b8191;
  font-weight: 900;
}

.side-menu .side-menu__icon {
  font-size: 16px;
  line-height: 0;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  text-align: center;
  color: #7b8191;
  fill: #7b8191;
}

.side-menu__icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25px;
}

.app-sidebar .slide .side-menu__item.active::before {
  content: "";
  width: 2px;
  height: 42px;
  background: #f2f7ff;
  position: absolute;
  left: 0;
  display: none;
}

.app-sidebar .side-item.side-item-category:not(:first-child) {
  margin-top: 15px;
}

.slide-menu {
  display: none;
  padding-left: 29px;
  list-style: none;
}

.slide.is-expanded .slide-menu {
  display: block;
}

.angle {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  -webkit-transition: -webkit-transform 320ms ease;
  transition: -webkit-transform 320ms ease;
  -o-transition: transform 320ms ease;
  transition: transform 320ms ease;
  transition: transform 320ms ease, -webkit-transform 320ms ease;
}

.main-sidebar-header .header-logo .desktop-dark,
.main-sidebar-header .header-logo .mobile-logo,
.main-sidebar-header .header-logo .mobile-dark {
  display: none;
}

.nav-link.with-sub .angle {
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  position: absolute;
  top: 17px;
  right: 23px;
  font-size: 12px;
}

.nav-link.with-sub .side-badge {
  right: 23px;
  position: absolute;
}

.sidebar-right .main-nav-line .nav-link {
  padding: 10px 18px 10px 21px;
  background: #f0f0f8;
}

.sidebar-right .main-nav-line .nav-link.active::before {
  bottom: 0;
}

.sidebar {
  position: fixed;
  display: block;
  top: 0px;
  width: 320px;
  bottom: 0;
  z-index: 9999;
  height: 100%;
  background: #fff;
  box-shadow: 0px 18px 36px 10px rgba(154, 154, 204, 0.1);
  border-left: 1px solid #ededf5;
}

.sidebar .tab-menu-heading {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  margin-bottom: 27px;
  background: #f9f9fb;
}

.sidebar .tabs-menu-body {
  margin-top: 56px;
}

.sidebar .tabs-menu ul li .active {
  background: var(--primary-bg-color);
  color: #fff;
  border: 1px solid var(--primary-bg-color);
}

.sidebar .tabs-menu ul li a {
  padding: 8px 8px;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 10px;
  border-radius: 3px;
  border: 1px solid #ebeaf1;
  font-weight: 500;
  background: transparent;
  color: #4a4a69;
  margin: 0 2px;
}

.sidebar .tabs-menu ul li:last-child a {
  margin-bottom: 0;
}

.sidebar .nav.panel-tabs {
  padding: 15px;
}

.sidebar .card {
  box-shadow: none;
  border: 0 !important;
}

.sidebar .list-group-item {
  margin-bottom: -2px;
}


.sidebar-right .list a {
  color: #4a4a69;
}

.tabs-menu ul li a {
  padding: 10px 12px 11px 13px;
  display: block;
}

.sidebar .nav.panel-tabs .side-menu__icon {
  margin-right: 2px;
  width: 16px;
  height: 16px;
}

.sidebar .tab-pane b {
  font-weight: 500;
}

.sidebar-mini .side-menu {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.sub-slide-menu .sub-side-menu__item.active {
  color: var(--primary-bg-color);
}

.side-menu__label1 {
  display: none !important;
}

@media (max-width: 480px) {
  .sidebar .tab-menu-heading .tabs-menu ul li {
    width: auto;
    margin: 0;
  }
}

@media (max-width: 480px) {
  .sidebar .tab-menu-heading .tabs-menu ul li {
    width: auto;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .sidebar-mini.sidenav-toggled .main-sidebar-header .logo-icon.dark-theme {
    display: none;
  }
}

@media (min-width: 768px) {
  .sidebar-mini.sidenav-toggled .main-sidebar-header .logo-icon.dark-theme {
    display: none;
  }
}

@media (min-width: 768px) {
  .row .sidebar.sidebar-left.sidebar-sm-show {
    left: 0;
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@media (min-width: 992px) {
  .row .sidebar.sidebar-left.sidebar-md-show {
    left: 0;
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@media (min-width: 992px) {
  .app.sidenav-toggled .app-content {
    margin-left: 80px;
  }

  .app.sidenav-toggled .app-sidebar {
    left: 0;
    width: 80px !important;
  }

  .app.sidenav-toggled .slide.is-expanded .slide-menu {
    display: none;
  }

  .side-badge {
    display: none;
  }
}

@media (max-width: 991px) {
  .app {
    overflow-x: hidden;
  }

  .app .app-sidebar {
    left: -240px;
  }

  .app.sidenav-toggled .app-content {
    margin-left: 0;
  }

  .app.sidenav-toggled .app-sidebar {
    left: 0;
  }

  .app.sidebar-gone.sidenav-toggled .app-sidebar {
    left: 0;
  }

  .app.sidenav-toggled .side-menu .side-menu__icon {
    margin-right: 13px !important;
    margin-left: 0;
  }
}

@media (min-width: 992px) {
  .app.sidenav-toggled .side-menu__label {
    display: none !important;
    position: relative;
    padding: 0;
    margin: 0;
    left: 0;
    color: rgba(255, 255, 255, 0.8);
    opacity: 1;
    background: transparent;
    font-size: 12px;
    box-shadow: none;
  }
}

@media (min-width: 992px) {
  .app-content {
    margin-left: 240px;
  }

  .app.sidenav-toggled .avatar-xl {
    width: 55px !important;
    height: 55px !important;
  }

  .app.sidebar-mini.sidenav-toggled .main-sidebar-header .header-logo .desktop-logo {
    display: none;
  }

  .app.sidebar-mini.sidenav-toggled .main-sidebar-header .header-logo .mobile-logo {
    display: block;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .mobile-logo {
    display: none !important;
  }

  .app.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
    line-height: 19px;
  }

  .app.sidebar-mini.sidenav-toggled .side-item.side-item-category {
    display: none;
  }

  .app.sidebar-mini.sidenav-toggled .side-item.side-item-category {
    display: none;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-item.side-item-category {
    display: block;
  }
}

@media (min-width: 992px) {
  .sidenav-toggled .angle {
    display: none;
  }

  .sidenav-toggled .side-menu {
    margin-top: 0px;
  }

  .sidenav-toggled .app-content {
    margin-left: 0;
  }

  .sidenav-toggled .app-sidebar {
    left: 0;
  }

  .sidenav-toggled .app-sidebar:hover {
    overflow: visible;
  }

  .sidenav-toggled .side-menu__item {
    overflow: hidden;
  }

  .sidenav-toggled .side-menu__item:hover {
    overflow: visible;
  }

  .sidenav-toggled .side-menu__item:hover .side-menu__label {
    opacity: 1;
  }

  .sidenav-toggled .side-menu__item:hover+.slide-menu {
    visibility: visible;
  }

  .sidenav-toggled .side-menu__label {
    display: block;
    position: absolute;
    top: 0;
    left: 50px;
    padding: 12px 5px 12px 20px;
    margin-left: -3px;
    line-height: 1;
    opacity: 0;
    background: #fff;
    color: #7b8191;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    -webkit-box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.2);
  }

  .sidenav-toggled .slide:hover .side-menu__label {
    opacity: 1;
  }

  .sidenav-toggled .slide:hover .slide-menu {
    max-height: 100%;
    opacity: 1;
    visibility: visible;
    z-index: 10;
  }

  .sidenav-toggled .slide .side-menu__label {
    border-bottom-right-radius: 0;
  }

  .app.sidenav-toggled .side-menu__item {
    display: block;
    padding: 11px 0 11px 0;
    margin: 0 auto;
    text-align: center;
    border-left: 0;
  }

  .app.sidenav-toggled .side-menu_label {
    display: block;
    font-size: 12px;
  }

  .app.sidenav-toggled .nav-badge {
    position: absolute;
    top: 8px;
    right: 28px;
    padding: 0.2rem 0.4rem;
    font-size: 11px;
  }
}

@media (max-width: 991px) and (min-width: 992px) {
  .desktop-logo {
    height: auto !important;
    text-align: inherit !important;
    margin: 0 !important;
    justify-content: inherit !important;
  }
}

@media (max-width: 991px) {
  .app-sidebar__toggle {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .main-sidebar-header {
    display: none !important;
  }

  .main-sidemenu {
    margin-top: 20px !important;
  }
}

@media (min-width: 992px) {
  .closed-menu.app.sidenav-toggled .app-sidebar {
    display: none !important;
  }

  .closed-menu.app.sidenav-toggled .app-content {
    margin-left: 0 !important;
  }

  .closed-menu.app.sidenav-toggled .main-header {
    padding-left: 0 !important;
  }
}

@media (min-width: 992px) {

  .app.sidebar-mini.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
    padding: 11px 20px 11px 22px !important;
    width: inherit;
    height: inherit;
  }
}

@media (min-width: 991px) and (max-width: 992px) {
  .main-sidebar-header .main-logo {
    display: block;
  }
}

@media (min-width: 1170px) {
  .row .sidebar.sidebar-left.sidebar-lg-show {
    left: 0;
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@media (min-width: 1200px) {
  .main-sidebar-sticky+.main-content {
    margin-left: 240px;
  }
}

@media print {
  .app-sidebar {
    display: none;
  }
}



